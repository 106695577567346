import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadProducts } from '../../actions-services/produits';
import store from '../../store';
import MyNavbar from '../layout/navbar';
import ProductItem from './productItem';
import ProductActions from './productActions';

const Products = ({ products }) => {
  useEffect(() => {
    store.dispatch(loadProducts());
  }, [loadProducts]);
  return (
    <Fragment>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <ProductActions />
            </div>
            {products !== null && products !== undefined && products !== [] ? (
              <div>
                <ProductItem />
              </div>
            ) : (
              <Fragment>
                <p>
                  Vous n'avez pas encore ajouté un produit, veuillez ajouter un
                  produit...
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Products.propTypes = {
  products: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.stock.products,
});

export default connect(mapStateToProps)(Products);
