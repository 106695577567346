import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './headerTable'
import InvoiceTableRow from './rawData'
import InvoiceTableBlankSpace from './blank'


const tableRowsCount = 5;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const InvoiceItemsTable = ({invoice, type}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader type={type} />
        <InvoiceTableRow items={invoice.items} type={type} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
    </View>
  );
  
  export default InvoiceItemsTable