import React,  {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { loadDemands } from '../../actions-services/demands';
import store from '../../store';
const DashboardActions = () => {
  useEffect(() => {
    store.dispatch(loadDemands());
  }, [loadDemands]);
  return (
    <div className='d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4'>
      <div>
        <h4 className='mb-3'>Liste des achats</h4>
        <p className='mb-0'>
          Un tableau de bord des achats permet au responsable des achats de
          suivre, d'évaluer et de contrôler efficacement, et optimiser tous les
          processus d'acquisition au sein d'une entreprise.
        </p>
      </div>
      <Link to='/achats/ajout' className='btn btn-primary add-list'>
        <i className='las la-plus mr-3'></i>Ajouter Achat
      </Link>
    </div>
  );
};

export default DashboardActions;
