import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import BillTo from './BillTo';
import InvoiceNo from './InvoiceNo';
import InvoiceItemsTable from './TableInvoice';
import InvoiceThankYouMsg from './thnaks';
import logo from '../../img/logo_ciocap.png';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  header: {
    flexDirection:'row',
  },
  logo: {
    width: 64,
    height: 56,
    left: '440px',
    top:'0px'
  },
});

const Invoice = ({ invoice, type }) => (
  <Document>
    <Page size={[595, 842]} style={styles.page}>
      <div style = {styles.header}>
        <Image style={styles.logo} src={logo} />
          {type==='achat' ? (
            <InvoiceTitle title="Bon d'achat" />
          ):(
            <InvoiceTitle title="Bon de vente" />
          )}
      </div>
      <div style = {styles.header}>
        <BillTo invoice={invoice} type = {type} />
        <InvoiceNo invoice={invoice} type={type} />
      </div>
      <InvoiceItemsTable invoice={invoice} type={type} />
    </Page>
  </Document>
);

export default Invoice;
