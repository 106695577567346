import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import InvoicePdf from '../reports/index'
const Details = ({ achats, fournisseurs, produits }) => {
  const [formData, setFormData] = useState({
    entries: '10',
    entries_debut: '0',
    current_entries: '10',
    count: 1,
  });
  const invoiceDataRef = useRef([]);
  const [invoiceData, setInvoiceData] = useState(invoiceDataRef.current);
  let { entries_debut, current_entries, entries, count } = formData;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (achat_id) => {
    let newInvoiceData = []
    for(let i =0;i<achats.length;i++){
      const achat = achats[i];
      if (achat.achat_id === achat_id){
        let items = [];
        let list_produits_ids = achat.produit_ids.split(',');
        let list_quantites = achat.quantites.split(',');
        let list_futs = achat.nbre_futs.split(',');
        let list_regions = achat.regions.split(',');
        let list_prices = achat.prices.split(',');
        for (let j=0;j<list_produits_ids.length;j++){
          items.push({sno:j+1,desc:produits
            .filter(
              (produit, idx) =>
                produit.produit_id === list_produits_ids[j]
            )
            .map((product) => {
              return product.name.toUpperCase() + ' '+product.category_name.toUpperCase()+  ' (' +product.code + ' ' + product.category_code + ' )'
              ;
            }),qty:list_quantites[j],rate:list_futs[j],region:list_regions[0],price:list_prices[j]})
        }
        newInvoiceData.push({id:achat_id,invoice_no:achat_id.slice(0, 5),description:'',balance:'',company:fournisseurs
        .filter(
          (fournisseur, idx) =>
            fournisseur.fournisseur_id === achat.fournisseur_id
        )
        .map((fournisseur) => {
          return fournisseur.name;
        }),email:fournisseurs
        .filter(
          (fournisseur, idx) =>
            fournisseur.fournisseur_id === achat.fournisseur_id
        )
        .map((fournisseur) => {
          return fournisseur.email;
        }),phone:fournisseurs
        .filter(
          (fournisseur, idx) =>
            fournisseur.fournisseur_id === achat.fournisseur_id
        )
        .map((fournisseur) => {
          return fournisseur.tel;
        }),address:fournisseurs
        .filter(
          (fournisseur, idx) =>
            fournisseur.fournisseur_id === achat.fournisseur_id
        )
        .map((fournisseur) => {
          return fournisseur.region + ' ,' + fournisseur.pays;
        }),trans_date:achat.date_arrivee,due_date:'',items:items})

        invoiceDataRef.current = newInvoiceData
      }
    }
    setShow(true)};

  const onChange = (e) => {
    setFormData({
      ...formData,
      entries_debut: '0',
      count: 1,
      [e.target.name]: e.target.value,
      current_entries: e.target.value,
    });
  };

  const nextClick = (e) => {
    e.preventDefault();
    let prev = parseInt(entries_debut) + parseInt(current_entries);
    let next = parseInt(entries) + parseInt(current_entries);
    if (achats.length < next) {
      if(prev>achats.length){
      
      }else{
        if (
          parseInt(entries) - parseInt(entries_debut) < parseInt(entries) ||
          achats.length < parseInt(current_entries)
        ) {
          setFormData({
            entries_debut: prev.toString(),
            entries: achats.length,
            count: count + 1,
            current_entries: current_entries,
          });
        }
      }
      
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count + 1,
        current_entries: current_entries,
      });
    }
  };
  const previousClick = (e) => {
    e.preventDefault();
    let step = parseInt(entries) - parseInt(entries_debut); //11 - 10 = 1; 28 - 25 = 3//
    let next = parseInt(entries) - step; // 11 - 1 = 10; 28 - 3 = 25
    let prev = next - parseInt(current_entries); // 10 - 10 = 9; 25 - 3 = 22;  50 - 3 = 47;
    if (prev < 0) {
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count - 1,
        current_entries: current_entries,
      });
    }
  };

  let achats_table = achats
    .filter(
      (item, idx) => parseInt(entries_debut) <= idx && idx < parseInt(entries)
    )
    .map((achat) => (
      <tr key={achat.achat_id}>
        <td>
          <div className='d-flex align-items-center'>
            <div>
              {achat.achat_id.slice(0, 5)}
              <p className='mb-0'>
                <small>
                  {achat.date_arrivee}
                </small>
              </p>
            </div>
          </div>
        </td>
        <td>
          {fournisseurs
            .filter(
              (fournisseur, idx) =>
                fournisseur.fournisseur_id === achat.fournisseur_id
            )
            .map((fournisseur) => {
              return fournisseur.name;
            })}
        </td>
        <td>
          <ul>
          {produits
            .filter((product, idx) =>
              achat.produit_ids.includes(product.produit_id)
            )
            .map((product) => (
              <li>{product.code + ' ' + product.category_code }</li>
            ))}
          </ul>
        </td>
        <td>
          <ul>
          {achat.quantites.split(',').map((quantity) => (
            <li>{quantity + ' Kg'}</li>
          ))}
          </ul>
          
        </td>
        <td>
          <ul>
          {achat.prices.split(',').map((price) => (
            <li>{price + ' Dhs'}</li>
          ))}
          </ul>
          
        </td>
        <td>
          <ul>
          {achat.nbre_futs.split(',').map((fut) => (
            <li>{fut}</li>
          ))}
          </ul>   
        </td>
        <td scope='row'>
          <ul>
          {achat.regions.split(',').map((region) => (
            <li>{region}</li>
          ))}
          </ul>
        </td>
        <td>
          <div className='d-flex align-items-center list-action'>
            <Link
              to={`/achat/${achat.achat_id}`}
              className='badge badge-info mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title='Details'
              data-original-title='View'
              href='#'
            >
              <i className='ri-eye-line mr-0' style={{fontSize:'20px'}}></i>
            </Link>
            <a
              className='badge bg-success mr-2'
              style={{cursor: 'pointer'}}
              data-toggle='tooltip'
              data-placement='top'
              title='PDF'
              data-original-title='Edit'
              onClick={() => handleShow(achat.achat_id)}
            >
            <i className="las la-file-pdf" style={{fontSize:'20px'}}></i>            
            </a>
          </div>
        </td>
      </tr>
    ));

  return (
    <div className='col-lg-12'>
      <div className='table-responsive rounded mb-3'>
        <div className='dataTables_wrapper no-footer'>
          <table
            className='data-table table mb-0 tbl-server-info dataTable no-footer'
            role='grid'
          >
            <thead className='bg-white text-uppercase'>
              <tr className='ligth ligth-data'>
             
                <th>Réf.</th>
                <th>Nom fournisseur</th>
                <th>Produits</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>fûts</th>
                <th>Région</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className='ligth-body'>{achats_table}</tbody>
          </table>
          <div className='dataTables_info' role='status'>
            Affichage de {entries_debut} à {entries} entrées
          </div>
          <div className='dataTables_paginate paging_simple_numbers'>
            <a
              className='paginate_button previous disabled'
              onClick={(e) => previousClick(e)}
            >
              Précédent
            </a>
            <span>
              <a className='paginate_button current'>{count}</a>
            </span>
            <a
              className='paginate_button next disabled'
              onClick={(e) => nextClick(e)}
            >
              Suivant
            </a>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
        <Modal.Header closeButton>
          <Modal.Title>Résumé d'achat</Modal.Title>
        </Modal.Header>
        <Modal.Body >{invoiceDataRef.current !== [] && <InvoicePdf invoice={invoiceDataRef.current[0]} type='achat'/>}</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Details.propTypes = {
  achats: PropTypes.array.isRequired,
  produits: PropTypes.array.isRequired,
  fournisseurs: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  achats: state.demande.achats,
  fournisseurs: state.fournisseur.fournisseurs,
  produits: state.stock.products,
});

export default connect(mapStateToProps)(Details);
