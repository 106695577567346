import {
  DEMAND_LOADED,
  DEMANDS_LOADED,
  ADD_DEMAND,
  ADD_ERROR,
  GET_ERROR,
  ADD_DEMAND_DATE,
  ADD_DEMAND_DATE_ERROR,
} from '../actions-services/types';

const initialState = {
  loading: true,
  loading_demand: true,
  achats: [],
  isAddedDate: false,
  msg: '',
  selected_demand: null,
  isAdded: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEMANDS_LOADED:
      localStorage.setItem('achats', payload.achats);
      return {
        ...state,
        ...payload,
        loading: false,
        isAdded: false,
      };
    case DEMAND_LOADED:
      localStorage.setItem('selected_demand', payload.achat);
      return {
        ...state,
        ...payload,
        loading_demand: false,
        isAddedDate: false,
        loading: false,
      };
    case ADD_DEMAND:
      return {
        ...state,
        loading: false,
        isAdded: true,
      };
    case ADD_DEMAND_DATE:
      return {
        ...state,
        loading: false,
        isAddedDate: true,
        msg: payload.msg,
      };
    case ADD_DEMAND_DATE_ERROR:
    case GET_ERROR:
    case ADD_ERROR:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
