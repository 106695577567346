import React from 'react';
import { Link } from 'react-router-dom';

const productActions = () => {
  return (
    <div className='d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4'>
      <div>
        <h4 className='mb-3'>Liste Produits</h4>
        <p className='mb-0'>
          La liste des produits dicte effectivement la présentation des produits
          et fournit un espace pour énumérer vos produits et votre offre de la
          manière la plus attrayante.
        </p>
      </div>
      <Link to='/produits/ajout' className='btn btn-primary add-list'>
        <i className='las la-plus mr-3'></i>Ajouter Produit
      </Link>
    </div>
  );
};

export default productActions;
