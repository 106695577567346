import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import { addCategory, loadCategories } from '../../actions-services/produits';
import MyNavbar from '../layout/navbar';
import PropTypes from 'prop-types';

const Category = ({ addCategory, categories, isAdded }) => {
  useEffect(() => {
    store.dispatch(loadCategories());
  }, [loadCategories]);

  const [formData, setFormData] = useState({
    nom: '',
    etat: '',
    code: '',
    description: '',
    msg: '',
  });
  const { etat, nom, code, description, msg } = formData;

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    for (let i = 0; i < categories.length; i++) {
      let category = categories[i];
      if (nom === category.name) {
        setFormData({ ...formData, msg: 'Nom de catégorie existe déjà !' });
        break;
      }
    }
    if (msg === '') {
      addCategory(nom, etat, code, description);
    }
  };

  let categories_option = categories.map((category) => (
    <option value={category.categorie_id}>
      {category.name + ' ' + category.etat}
    </option>
  ));
  if (isAdded) {
    return <Redirect to='/categories' />;
  }
  return (
    <div>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid add-form-list'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Ajouter Categorie</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={(e) => handleAdd(e)}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>{'Nom catégorie *' + msg}</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le nom'
                            data-errors='Le nom est obligatoire !'
                            name='nom'
                            value={nom}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Etat catégorie *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le code'
                            data-errors='Le code est obligatoire !'
                            name='etat'
                            value={etat}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Code catégorie *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le code'
                            data-errors='Le code est obligatoire !'
                            name='code'
                            value={code}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>

                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Description / Détails catégorie</label>
                          <textarea
                            className='form-control'
                            rows='4'
                            name='description'
                            value={description}
                            onChange={(e) => onChange(e)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-primary mr-2'>
                      Ajouter Catégorie
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Category.propTypes = {
  addCategory: PropTypes.func.isRequired,
  isAdded: PropTypes.bool,
  categories: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAdded: state.stock.isAddedCategory,
  categories: state.stock.categories,
});

export default connect(mapStateToProps, { addCategory })(Category);
