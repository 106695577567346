import {
  STOCK_LOADED,
  STOCK_ERROR,
  ADD_PRODUCT,
  ADD_LOT,
  ADD_CATEGORY,
  CATEGORIES_LOADED,
  CATEGORIES_ERROR,
} from '../actions-services/types';

const initialState = {
  products: [],
  categories: [],
  new_product_msg: null,
  new_lot_msg: null,
  new_category_msg: null,
  loading: true,
  isAdded: false,
  isAddedCategory: false,
  isAddedLot: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STOCK_LOADED:
      localStorage.setItem('products', payload.products);
      return {
        ...state,
        ...payload,
        loading: false,
        isAdded: false,
        isAddedLot: false,
      };
    case CATEGORIES_LOADED:
      localStorage.setItem('categories', payload.categories);
      return {
        ...state,
        ...payload,
        loading: false,
        isAddedCategory: false,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        new_product_msg: payload.msg,
        loading: false,
        isAdded: true,
      };
    case ADD_LOT:
      return {
        ...state,
        new_lot_msg: payload.msg,
        loading: false,
        isAddedLot: true,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        new_category_msg: payload.msg,
        loading: false,
        isAddedCategory: true,
      };
    case STOCK_ERROR:
      return {
        ...state,
        ...payload,
        loading: true,
      };
    case CATEGORIES_ERROR:
      return {
        ...state,
        ...payload,
        loading: true,
      };
    default:
      return state;
  }
}
