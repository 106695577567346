import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import { addProduct, loadCategories } from '../../actions-services/produits';
import MyNavbar from '../layout/navbar';
import PropTypes from 'prop-types';

const Product = ({ addProduct, categories, isAdded }) => {
  useEffect(() => {
    store.dispatch(loadCategories());
  }, [loadCategories]);

  const [formData, setFormData] = useState({
    nom: '',
    category: '',
    code: '',
    description: '',
    img: '',
  });
  const { category, nom, code, description, img } = formData;
  const readFileAsBinary = async (e) => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        let binaryString = event.target.result;
        setFormData({ ...formData, img: btoa(binaryString) });
      };
      reader.readAsBinaryString(file);
    }
  };

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    addProduct(nom, category, code, description, img);
  };

  let categories_option = categories.map((category) => (
    <option value={category.categorie_id}>
      {category.name + ' ' + category.etat}
    </option>
  ));
  if (isAdded) {
    return <Redirect to='/produits'/>;
  }
  return (
    <div>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid add-form-list'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Ajouter Produit</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={(e) => handleAdd(e)}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Nom du produit *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le nom'
                            data-errors='Le nom est obligatoire !'
                            name='nom'
                            value={nom}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Code du produit *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le code'
                            data-errors='Le code est obligatoire !'
                            name='code'
                            value={code}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Catégorie Produit *</label>
                          <select
                            name='category'
                            value={category}
                            onChange={(e) => onChange(e)}
                            className='form-control'
                          >
                            <option value='' disabled>
                              Choisir une catégorie
                            </option>
                            {categories_option}
                          </select>
                        </div>
                      </div>

                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Description / Détails du produit</label>
                          <textarea
                            className='form-control'
                            rows='4'
                            name='description'
                            value={description}
                            onChange={(e) => onChange(e)}
                          ></textarea>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Image</label>
                          <input
                            type='file'
                            className='form-control image-file'
                            accept='image/png, image/jpeg'
                            id='file'
                            name='img'
                            onChange={(e) => readFileAsBinary(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-primary mr-2'>
                      Ajouter Produit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Product.propTypes = {
  addProduct: PropTypes.func.isRequired,
  isAdded: PropTypes.bool,
  categories: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAdded: state.stock.isAdded,
  categories: state.stock.categories,
});

export default connect(mapStateToProps, { addProduct })(Product);
