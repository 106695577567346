import {
  FOURNISSEURS_LOADED,
  ADD_FOURNISSEUR,
  GET_ERROR,
  ADD_ERROR,
} from '../actions-services/types';

const initialState = {
  loading: true,
  fournisseurs: [],
  errors: [],
  new_fournisseur: null,
  isAdded: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FOURNISSEURS_LOADED:
      localStorage.setItem('fournisseurs', payload.fournisseurs);
      return {
        ...state,
        ...payload,
        loading: false,
        isAdded:false,
      };
    case ADD_FOURNISSEUR:
      return {
        ...state,
        new_fournisseur: payload.msg,
        loading: false,
        isAdded: true,
      };
    case GET_ERROR:
      return {
        ...state,
        loading: true,
      };
    case ADD_ERROR:
      localStorage.setItem('errors', payload);
      return {
        ...state,
        ...payload,
        loading: false,
      };
    default:
      return state;
  }
}
