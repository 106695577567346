import React, { useState, useRef, useEffect } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { CCollapse } from '@coreui/react';
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addDate } from '../../actions-services/demands';

import MyNavbar from '../layout/navbar';

const Demand = ({
  demande: { achats, isAddedDate },
  stock: { products },
  fournisseur: { fournisseurs },
  auth,
  user,
  match,
  addDate,
}) => {
  let achat_list = achats.filter((item) => item.achat_id == match.params.id);
  let achat = achat_list[0];
  const [formData, setFormData] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    product_ico: false,
  });

  const counter = useRef(0);
  const collapses = useRef([]);
  const dates_fermentation = useRef([]);
  const dataChart = useRef([]);
  const [collapses_state, setCollapses] = useState(collapses.current);
  const [counter_state, setCounter] = useState(counter.current);
  const [dates_fermentation_state, setDatesFermentation] = useState(
    dates_fermentation.current
  );
  const [dataChartState, setDataChart] = useState(dataChart.current);
  const { tab1, tab2, tab3, product_ico } = formData;

  const setVisible = (counter_collapse, value) => {
    let newCollapses = collapses_state;
    newCollapses[counter_collapse]['visible'] = value;
    collapses.current = newCollapses;
    setCollapses(newCollapses);
    setFormData({ ...formData, tab1: false, tab2: false, tab3: true });
  };
  const handleClick1 = (event) => {
    // toggle isActive state on click
    setFormData({ ...formData, tab1: true, tab2: false, tab3: false });
  };
  const handleClick2 = (event) => {
    // toggle isActive state on click
    setDataChart(dataChart.current);
    setFormData({ ...formData, tab1: false, tab2: true, tab3: false });
  };

  const handleClick3 = (event) => {
    // toggle isActive state on click
    setFormData({ ...formData, tab1: false, tab2: false, tab3: true });
  };
  const handleClick4 = (event, product_ico_new) => {
    // toggle isActive state on click
    setFormData({ ...formData, product_ico: product_ico_new });
  };

  const onChangeDate = (e, idx_produit) => {
    const newValues = dates_fermentation_state;
    newValues[idx_produit]['value'] = e.target.value;
    dates_fermentation.current = newValues;
    setDatesFermentation(newValues);
  };

  const handleAddDate = async (e, date_fer, index_pr) => {
    e.preventDefault();
    if (date_fer == '') {
      const newValues = dates_fermentation_state;
      newValues[index_pr]['msg'] = "Vous n'avez pas rempli le champs date";
      dates_fermentation.current = newValues;
      setDatesFermentation(newValues);
      setFormData({ ...formData, tab1: true, tab2: false, tab3: false });
    } else {
      const newValues = dates_fermentation_state;
      newValues[index_pr]['msg'] = '';
      dates_fermentation.current = newValues;
      setDatesFermentation(newValues);
      addDate(match.params.id, date_fer, index_pr);
    }
  };

  const listeOptions = () => {
    let list_quantites = [];
    let list_prices = [];
    let list_codes = [];
    let list_date_fermenation = [];
    let dataChartPush = [];
    if (achat !== null || achat !== []) {
      list_quantites = achat.quantites.split(',');
      list_prices = achat.prices.split(',');
      list_date_fermenation = achat.dates_fermentation.split(',');
      list_codes = achat.code.split(',');

      for (let i = 0; i < list_date_fermenation.length; i++) {
        if (list_date_fermenation[i] !== '') {
          let quantiteData = [];
          let dateOf = [];
          for (let t = 0; t < 50; t++) {
            let quantite = list_quantites[i] + t;
            let date = t + 1;
            dateOf.push(date);
            quantiteData.push(quantite);
          }
          dataChartPush.push({
            labels: dateOf,
            datasets: [{ label: list_codes[i], data: quantiteData }],
          });
        }
      }
      dataChart.current = dataChartPush;
    }
    return 0;
  };
  const listeLots = () => {
    let list_produits_ids = [];
    let list_quantites = [];
    let list_prices = [];
    let list_codes = [];
    let list_futs = [];
    let list_dechets = [];
    let liste_lots = [];
    let list_quantites_current = [];
    if (achat !== null || achat !== []) {
      list_produits_ids = achat.produit_ids.split(',');
      list_quantites = achat.quantites.split(',');
      list_prices = achat.prices.split(',');
      list_quantites_current = achat.quantites_current.split(',');
      list_codes = achat.code.split(',');
      list_futs = achat.nbre_futs.split(',');
      list_dechets = achat.dechets.split(',');
      liste_lots = achat.achat_lots;
    }
    const str1 = '#';
    let lot_from_achat = {};

    const renderBranchInitial = (counter_branch, code, lots) => {
      const renderSubBranchInitial = (
        counter_subBranch,
        code_sub,
        lots_sub
      ) => {
        const renderSubSubBranchInitial = (
          counter_subsubBranch,
          code_subsub,
          lots_subsub
        ) => {
          const renderSubSubSubBranchesInitial = () => {
            const subsubsubResult = [];
            lots_subsub = lots_subsub.filter((item) =>
              item.code_lot_from.includes(code_subsub)
            );
            for (let n = 0; n < lots_subsub.length; n++) {
              let lot_selected_subsub = lots_subsub[n];

              let list_codes_lot_subsub = lot_selected_subsub.code.split(',');
              let code_lot_from_subsub = lot_selected_subsub.code_lot_from.split(
                ','
              );
              //iste_lots = achat.achat_lots;
              if (code_lot_from_subsub.includes(code_subsub)) {
                for (let t = 0; t < list_codes_lot_subsub.length; t++) {
                  counter.current = counter.current + 1;
                  collapses.current.push({
                    visible: false,
                    code: list_codes_lot_subsub[t],
                  });
                }
              }
            }
            return subsubsubResult;
          };

          renderSubSubSubBranchesInitial();
          return 0;
        };
        const renderSubSubBranchesInitial = () => {
          const subsubResult = [];
          lots_sub = lots_sub.filter((item) =>
            item.code_lot_from.includes(code_sub)
          );
          for (let m = 0; m < lots_sub.length; m++) {
            let lot_selected_sub = lots_sub[m];
            let list_codes_lot_sub = lot_selected_sub.code.split(',');
            let code_lot_from_sub = lot_selected_sub.code_lot_from.split(',');
            //iste_lots = achat.achat_lots;
            if (code_lot_from_sub.includes(code_sub)) {
              for (let h = 0; h < list_codes_lot_sub.length; h++) {
                counter.current = counter.current + 1;
                collapses.current.push({
                  visible: false,
                  code: list_codes_lot_sub[h],
                });
                subsubResult.push(
                  renderSubSubBranchInitial(
                    counter.current - 1,
                    list_codes_lot_sub[h],
                    liste_lots
                  )
                );
              }
            }
          }
          return subsubResult;
        };
        renderSubSubBranchesInitial();
        return 0;
      };

      const renderSubBranchesInitial = () => {
        const subResult = [];
        lots = lots.filter((item) => item.code_lot_from.includes(code));
        for (let j = 0; j < lots.length; j++) {
          let lot_selected = lots[j];

          let list_codes_lot = lot_selected.code.split(',');
          let code_lot_from = lot_selected.code_lot_from.split(',');
          //iste_lots = achat.achat_lots;
          if (code_lot_from.includes(code)) {
            for (let k = 0; k < list_codes_lot.length; k++) {
              counter.current = counter.current + 1;

              collapses.current.push({
                visible: false,
                code: list_codes_lot[k],
              });
              subResult.push(
                renderSubBranchInitial(
                  counter.current - 1,
                  list_codes_lot[k],
                  liste_lots
                )
              );
            }
          }
        }
        return subResult;
      };
      renderSubBranchesInitial();
      return (
        <>
          <CButton
            style={{ marginBottom: '10px' }}
            onClick={() =>
              setVisible(
                counter_branch,
                !collapses_state[counter_branch]['visible']
              )
            }
          >
            {code}
          </CButton>

          <CRow>
            <CCol xs={22}>
              <CCollapse visible={collapses_state[counter_branch]['visible']}>
                <CCard className='mt-3'></CCard>
              </CCollapse>
            </CCol>
          </CRow>
        </>
      );
    };
    const renderBranch = (
      counter_branch,
      idproduit,
      quantity,
      quantity_cur,
      futs,
      dechets,
      code,
      lots
    ) => {
      let str = products
        .filter((item) => item.produit_id === idproduit)
        .map((produit) => {
          return produit.name + ' ' + produit.category_name;
        });

      const renderSubBranch = (
        counter_subBranch,
        idproduit_sub,
        quantity_sub,
        quantity_cur_sub,
        futs_sub,
        dechets_sub,
        code_sub,
        lots_sub
      ) => {
        let str_sub = products
          .filter((item) => item.produit_id === idproduit_sub)
          .map((produit) => {
            return produit.name + ' ' + produit.category_name;
          });

        const renderSubSubBranch = (
          counter_subsubBranch,
          idproduit_subsub,
          quantity_subsub,
          quantity_cur_subsub,
          futs_subsub,
          dechets_subsub,
          code_subsub,
          lots_subsub
        ) => {
          let str_subsub = products
            .filter((item) => item.produit_id === idproduit_subsub)
            .map((produit) => {
              return produit.name + ' ' + produit.category_name;
            });
          let quantity_traite_subsub = quantity_subsub - quantity_cur_subsub;

          const renderSubSubSubBranch = (
            counter_subsubsubBranch,
            idproduit_subsubsub,
            quantity_subsubsub,
            quantity_cur_subsubsub,
            futs_subsubsub,
            dechets_subsubsub,
            code_subsubsub,
            lots_subsubsub
          ) => {
            let str_subsubsub = products
              .filter((item) => item.produit_id === idproduit_subsubsub)
              .map((produit) => {
                return produit.name + ' ' + produit.category_name;
              });

            return (
              <>
                <CButton
                  style={{ marginBottom: '10px' }}
                  onClick={() =>
                    setVisible(
                      counter_subsubsubBranch,
                      !collapses_state[counter_subsubsubBranch]['visible']
                    )
                  }
                >
                  {code_subsubsub}
                </CButton>

                <CRow>
                  <CCol xs={22}>
                    <CCollapse
                      visible={
                        collapses_state[counter_subsubsubBranch]['visible']
                      }
                    >
                      <CCard className='mt-3'>
                        <CCardBody>
                          <CCardTitle>Nom produit : {str_subsubsub}</CCardTitle>
                          <CCardText>
                            Quantité total : {quantity_subsubsub}
                            {' Kg'}
                          </CCardText>
                          <CCardText>
                            Nombre/Type fûts : {futs_subsubsub}
                          </CCardText>
                          <CCardText>
                            Quantité restante : {quantity_cur_subsubsub}
                            {' Kg'}
                          </CCardText>
                        </CCardBody>
                      </CCard>
                    </CCollapse>
                  </CCol>
                </CRow>
              </>
            );
          };
          const renderSubSubSubBranches = () => {
            const subsubsubResult = [];
            lots_subsub = lots_subsub.filter((item) =>
              item.code_lot_from.includes(code_subsub)
            );
            for (let n = 0; n < lots_subsub.length; n++) {
              let lot_selected_subsub = lots_subsub[n];
              let list_produits_ids_lot_subsub = lot_selected_subsub.produit_ids.split(
                ','
              );
              let list_quantites_lot_subsub = lot_selected_subsub.quantite_produits.split(
                ','
              );
              let list_quantites_current_lot_subsub = lot_selected_subsub.quantites_current.split(
                ','
              );
              let list_futs_lot_subsub = lot_selected_subsub.futs.split(',');
              let list_dechets_lot_subsub = lot_selected_subsub.dechets.split(
                ','
              );
              let list_codes_lot_subsub = lot_selected_subsub.code.split(',');
              let code_lot_from_subsub = lot_selected_subsub.code_lot_from.split(
                ','
              );
              //iste_lots = achat.achat_lots;
              if (code_lot_from_subsub.includes(code_subsub)) {
                for (let t = 0; t < list_codes_lot_subsub.length; t++) {
                  counter.current = counter.current + 1;

                  subsubsubResult.push(
                    renderSubSubSubBranch(
                      counter.current - 1,
                      list_produits_ids_lot_subsub[t],
                      list_quantites_lot_subsub[t],
                      list_quantites_current_lot_subsub[t],
                      list_futs_lot_subsub[t],
                      list_dechets_lot_subsub[t],
                      list_codes_lot_subsub[t],
                      liste_lots
                    )
                  );
                }
              }
            }
            return subsubsubResult;
          };
          return (
            <>
              <CButton
                style={{ marginBottom: '10px' }}
                onClick={() =>
                  setVisible(
                    counter_subsubBranch,
                    !collapses_state[counter_subsubBranch]['visible']
                  )
                }
              >
                {code_subsub}
              </CButton>

              <CRow>
                <CCol xs={22}>
                  <CCollapse
                    visible={collapses_state[counter_subsubBranch]['visible']}
                  >
                    <CCard className='mt-3'>
                      <CCardBody>
                        <CCardTitle>Nom produit : {str_subsub}</CCardTitle>
                        <CCardText>
                          Quantité total : {quantity_subsub}
                          {' Kg'}
                        </CCardText>
                        <CCardText>Nombre/Type fûts : {futs_subsub}</CCardText>
                        <CCardText>
                          Quantité restante : {quantity_cur_subsub}
                          {' Kg'}
                        </CCardText>
                        <CCardText>
                          Total déchets : {dechets_subsub}
                          {' Kg'}
                        </CCardText>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='row'>
                              {renderSubSubSubBranches()}
                            </div>
                          </div>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                </CCol>
              </CRow>
            </>
          );
        };
        const renderSubSubBranches = () => {
          const subsubResult = [];
          lots_sub = lots_sub.filter((item) =>
            item.code_lot_from.includes(code_sub)
          );
          for (let m = 0; m < lots_sub.length; m++) {
            let lot_selected_sub = lots_sub[m];
            let list_produits_ids_lot_sub = lot_selected_sub.produit_ids.split(
              ','
            );
            let list_quantites_lot_sub = lot_selected_sub.quantite_produits.split(
              ','
            );
            let list_quantites_current_lot_sub = lot_selected_sub.quantites_current.split(
              ','
            );
            let list_futs_lot_sub = lot_selected_sub.futs.split(',');
            let list_dechets_lot_sub = lot_selected_sub.dechets.split(',');
            let list_codes_lot_sub = lot_selected_sub.code.split(',');
            let code_lot_from_sub = lot_selected_sub.code_lot_from.split(',');
            //iste_lots = achat.achat_lots;
            if (code_lot_from_sub.includes(code_sub)) {
              for (let h = 0; h < list_codes_lot_sub.length; h++) {
                counter.current = counter.current + 1;

                subsubResult.push(
                  renderSubSubBranch(
                    counter.current - 1,
                    list_produits_ids_lot_sub[h],
                    list_quantites_lot_sub[h],
                    list_quantites_current_lot_sub[h],
                    list_futs_lot_sub[h],
                    list_dechets_lot_sub[h],
                    list_codes_lot_sub[h],
                    liste_lots
                  )
                );
              }
            }
          }
          return subsubResult;
        };
        return (
          <>
            <CButton
              style={{ marginBottom: '10px' }}
              onClick={() =>
                setVisible(
                  counter_subBranch,
                  !collapses_state[counter_subBranch]['visible']
                )
              }
            >
              {code_sub}
            </CButton>

            <CRow>
              <CCol xs={22}>
                <CCollapse
                  visible={collapses_state[counter_subBranch]['visible']}
                >
                  <CCard className='mt-3'>
                    <CCardBody>
                      <CCardTitle>Nom produit : {str_sub}</CCardTitle>
                      <CCardText>
                        Quantité total : {quantity_sub}
                        {' Kg'}
                      </CCardText>
                      <CCardText>Nombre/Type fûts : {futs_sub}</CCardText>
                      <CCardText>
                        Quantité restante : {quantity_cur_sub}
                        {' Kg'}
                      </CCardText>
                      <CCardText>
                        Total déchets : {dechets_sub}
                        {' Kg'}
                      </CCardText>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='row'>{renderSubSubBranches()}</div>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCollapse>
              </CCol>
            </CRow>
          </>
        );
      };

      const renderSubBranches = () => {
        const subResult = [];
        let liste_lots = achat.achat_lots;
        lots = liste_lots.filter((item) => item.code_lot_from.includes(code));
        for (let j = 0; j < lots.length; j++) {
          let lot_selected = lots[j];
          let list_produits_ids_lot = lot_selected.produit_ids.split(',');
          let list_quantites_lot = lot_selected.quantite_produits.split(',');
          let list_quantites_current_lot = lot_selected.quantites_current.split(
            ','
          );
          let list_futs_lot = lot_selected.futs.split(',');
          let list_dechets_lot = lot_selected.dechets.split(',');
          let list_codes_lot = lot_selected.code.split(',');
          let code_lot_from = lot_selected.code_lot_from.split(',');
          if (code_lot_from.includes(code)) {
            for (let k = 0; k < list_codes_lot.length; k++) {
              counter.current = counter.current + 1;
              subResult.push(
                renderSubBranch(
                  counter.current - 1,
                  list_produits_ids_lot[k],
                  list_quantites_lot[k],
                  list_quantites_current_lot[k],
                  list_futs_lot[k],
                  list_dechets_lot[k],
                  list_codes_lot[k],
                  liste_lots
                )
              );
            }
          }
        }
        return subResult;
      };
      return (
        <>
          <CButton
            style={{ marginBottom: '10px' }}
            onClick={() =>
              setVisible(
                counter_branch,
                !collapses_state[counter_branch]['visible']
              )
            }
          >
            {code}
          </CButton>

          <CRow>
            <CCol xs={22}>
              <CCollapse visible={collapses_state[counter_branch]['visible']}>
                <CCard className='mt-3'>
                  <CCardBody>
                    <CCardTitle>Nom produit : {str}</CCardTitle>
                    <CCardText>
                      Quantité total : {quantity}
                      {' Kg'}
                    </CCardText>
                    <CCardText>Nombre/Type fûts : {futs}</CCardText>
                    <CCardText>
                      Quantité restante : {quantity_cur}
                      {' Kg'}
                    </CCardText>
                    <CCardText>
                      Total déchets : {dechets}
                      {' Kg'}
                    </CCardText>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='row'>{renderSubBranches()}</div>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCollapse>
            </CCol>
          </CRow>
        </>
      );
    };
    const result = [];
    if (counter.current === 0) {
      for (let i = 0; i < list_codes.length; i++) {
        counter.current = counter.current + 1;
        collapses.current.push({ visible: false, code: list_codes[i] });
        result.push(
          renderBranchInitial(counter.current - 1, list_codes[i], liste_lots)
        );
      }
    } else {
      counter.current = 0;
      for (let i = 0; i < list_codes.length; i++) {
        counter.current = counter.current + 1;
        result.push(
          renderBranch(
            counter.current - 1,
            list_produits_ids[i],
            list_quantites[i],
            list_quantites_current[i],
            list_futs[i],
            list_dechets[i],
            list_codes[i],
            liste_lots
          )
        );
      }
    }

    //list_codes.map((code, idxG)

    return result;
  };

  const listeProduits = () => {
    const renderProductBranch = (
      index_produit,
      code,
      quantite,
      price,
      futs,
      date_fermentation,
      region
    ) => {
      return (
        <div>
          <div className='form-row'>
            <div className='col-sm'>
              <div className='form-group'>
                <label htmlFor='exampleInputDisabled5'>Code Produit</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  defaultValue={code}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label htmlFor='exampleInputDisabled5'>Quantité</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  defaultValue={quantite + ' Kg'}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label htmlFor='exampleInputDisabled5'>Prix Unitaire</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  defaultValue={price + ' Dhs'}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label htmlFor='exampleInputDisabled5'>Nombre/Type fûts</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  defaultValue={futs}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
          </div>
          <div className='form-row'>
            {date_fermentation !== '' &&
            date_fermentation !== NaN &&
            date_fermentation !== [] &&
            date_fermentation !== undefined ? (
              <div className='col-sm'>
                <div className='form-group'>
                  <label htmlFor='exampleInputDisabled5'>
                    Date de férmentation
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='exampleInputDisabled5'
                    disabled=''
                    defaultValue={date_fermentation}
                  />

                  <div className='help-block with-errors'></div>
                </div>
              </div>
            ) : (
              <>
                <div className='col-sm'>
                  <div className='form-group'>
                    <label htmlFor='exampleInputDisabled5'>
                      Date de férmentation
                    </label>
                    <input
                      type='date'
                      className='form-control'
                      placeholder='Entrer la date de fermentation'
                      data-errors='La date est obligatoire !'
                      name='dates_fermentation'
                      values={
                        dates_fermentation.current[index_produit]['value']
                      }
                      onChange={(e) => onChangeDate(e, index_produit)}
                      required
                    />
                    {dates_fermentation.current[index_produit]['msg'] !==
                      '' && (
                      <div
                        className='help-block with-errors'
                        style={{ color: 'red' }}
                      >
                        {dates_fermentation.current[index_produit]['msg']}
                      </div>
                    )}

                    <button
                      type='button'
                      className='btn btn-primary mt-2'
                      onClick={(e) =>
                        handleAddDate(
                          e,
                          dates_fermentation_state[index_produit]['value'],
                          index_produit
                        )
                      }
                    >
                      Ajouter date
                      <i
                        className='las la-calendar-check'
                        style={{ fontSize: '28px' }}
                      ></i>
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className='col-sm'>
              <div className='form-group'>
                <label htmlFor='exampleInputDisabled5'>Région</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  defaultValue={region}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
          </div>
          <p style={{ color: 'black', fontSize: '30px' }}>
            -------------------------------------------------------
          </p>
        </div>
      );
    };

    const list_quantites = achat.quantites.split(',');
    const list_prices = achat.prices.split(',');
    const list_futs = achat.nbre_futs.split(',');
    const list_date_fermenation = achat.dates_fermentation.split(',');
    const list_region = achat.regions.split(',');
    const list_codes = achat.code.split(',');

    const result_list = [];
    for (let i = 0; i < list_codes.length; i++) {
      if (dates_fermentation.current.length < list_codes.length) {
        dates_fermentation.current.push({ value: '', msg: '' });
      }
      result_list.push(
        renderProductBranch(
          i,
          list_codes[i],
          list_quantites[i],
          list_prices[i],
          list_futs[i],
          list_date_fermenation[i],
          list_region[i]
        )
      );
    }
    return result_list;
  };

  return (
    <>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>
                      Réf. N° {achat.achat_id.slice(0, 5)}
                    </h4>
                  </div>
                </div>
                <div className='card-body'>
                  <ul
                    className='d-flex nav nav-pills mb-3 text-center profile-tab'
                    id='profile-pills-tab'
                    role='tablist'
                  >
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-toggle='pill'
                        href='#profile2'
                        role='tab'
                        aria-selected='true'
                        onClick={(e) => handleClick1(e)}
                      >
                        Détails d'achat
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-toggle='pill'
                        href='#profile3'
                        role='tab'
                        aria-selected='true'
                        onClick={(e) => handleClick3(e)}
                      >
                        Listes des lots
                      </a>
                    </li>
                  </ul>

                  <div className='profile-content tab-content'>
                    <div
                      id='profile2'
                      className={
                        tab1 ? 'tab-pane fade active show' : 'tab-pane fade'
                      }
                    >
                      <div className='row'>
                        <div className='col-lg-8'>
                          <div className='row'>
                            <form>
                              <div className='form-group'>
                                <label htmlFor='exampleInputDisabled5'>
                                  Fournisseur
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='exampleInputDisabled5'
                                  disabled=''
                                  defaultValue={fournisseurs
                                    .filter(
                                      (fourn, idx) =>
                                        fourn.fournisseur_id ===
                                        achat.fournisseur_id
                                    )
                                    .map((fourn) => {
                                      return fourn.name;
                                    })}
                                />
                              </div>
                              <fieldset>
                                <p>
                                  Produits achetés{' '}
                                  <span aria-label='required'>
                                    {product_ico === false ? (
                                      <i
                                        className='las la-angle-double-down'
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>
                                          handleClick4(e, !product_ico)
                                        }
                                      ></i>
                                    ) : (
                                      <i
                                        class='las la-angle-double-up'
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>
                                          handleClick4(e, !product_ico)
                                        }
                                      ></i>
                                    )}
                                  </span>
                                  .
                                </p>

                                {product_ico === true && listeProduits()}
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='profile-content tab-content'>
                    <div
                      id='profile3'
                      className={
                        tab2 ? 'tab-pane fade active show' : 'tab-pane fade'
                      }
                    >
                      <div className='col'>
                        <div className='col-lg-8'>
                          <div className='col'>
                            {achat.produit_ids === '' &&
                            achat.produit_ids === null ? (
                              <p className='mb-1'>Aucun produit ajouté</p>
                            ) : (
                              listeOptions()
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='profile-content tab-content'>
                    <div
                      id='profile4'
                      className={
                        tab3 ? 'tab-pane fade active show' : 'tab-pane fade'
                      }
                    >
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='row'>
                            {tab3 === true && listeLots()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='mb-1'>
                    Date d'entrée :{achat.date_arrivee}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Demand.propTypes = {
  demande: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  stock: PropTypes.object.isRequired,
  fournisseur: PropTypes.object.isRequired,
  addDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  demande: state.demande,
  auth: state.auth,
  user: state.user,
  stock: state.stock,
  fournisseur: state.fournisseur,
});

export default connect(mapStateToProps, { addDate })(Demand);
