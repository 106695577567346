import axios from 'axios';
import { setAlert } from './alert';
import { loadDemands } from './demands';
import {
  ADD_PRODUCT,
  STOCK_ERROR,
  STOCK_LOADED,
  CATEGORIES_LOADED,
  CATEGORIES_ERROR,
  ADD_CATEGORY,
} from './types';

//LOAD CATEGORIES

export const loadCategories = () => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/categories');
    dispatch({
      type: CATEGORIES_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_ERROR,
    });
  }
};

export const loadProducts = () => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/stock');
    dispatch({
      type: STOCK_LOADED,
      payload: res.data,
    });
    dispatch(loadDemands());
  } catch (error) {
    dispatch({
      type: STOCK_ERROR,
    });
  }
};
export const addCategory = (name, etat, code, description) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ name, etat, code, description });
  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/categories', body, config);
    dispatch({
      type: ADD_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
    dispatch({
      type: STOCK_ERROR,
    });
  }
};
export const addProduct = (
  name,
  categorie_id,
  code,
  description,
  img
) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ name, categorie_id, code, description, img });
  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/produits', body, config);
    dispatch({
      type: ADD_PRODUCT,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
    dispatch({
      type: STOCK_ERROR,
    });
  }
};
