import React from 'react';
import { Link } from 'react-router-dom';

const DashboardActions = () => {
  return (
    <div className='d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4'>
      <div>
        <h4 className='mb-3'>Liste Clients</h4>
        <p className='mb-0'>Créez et gérez votre liste de clients.</p>
      </div>
      <Link to='/clients/ajout' className='btn btn-primary add-list'>
        <i className='las la-plus mr-3'></i>Ajouter Client
      </Link>
    </div>
  );
};

export default DashboardActions;
