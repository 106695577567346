import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { addFournisseur } from '../../actions-services/fournisseurs';
import MyNavbar from '../layout/navbar';
import PropTypes from 'prop-types';

const Fournisseur = ({ addFournisseur, isAdded }) => {
  const [formData, setFormData] = useState({
    nom: '',
    tel: '',
    email: '',
    region: '',
    pays: '',
  });
  const { tel, nom, email, region, pays } = formData;

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = (e) => {
    e.preventDefault();
    addFournisseur(nom, tel, email, region, pays);
  };

  if (isAdded) {
    return <Redirect to='/fournisseurs' />;
  }
  return (
    <div>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid add-form-list'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Ajouter Fournisseur</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={(e) => handleAdd(e)}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Nom du fournisseur</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le nom'
                            data-errors='Le nom est obligatoire !'
                            name='nom'
                            value={nom}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Email*</label>
                          <input
                            type='email'
                            className='form-control'
                            placeholder="Entrer l'email"
                            data-errors="L'email est obligatoire !"
                            name='email'
                            value={email}
                            onChange={(e) => onChange(e)}
                          
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Téléphone *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le téléphone'
                            data-errors='Le téléphone est obligatoire !'
                            name='tel'
                            value={tel}
                            onChange={(e) => onChange(e)}
                   
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Région *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer la région'
                            data-errors='La région est obligatoire !'
                            name='region'
                            value={region}
                            onChange={(e) => onChange(e)}
             
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Pays *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le pays'
                            data-errors='Le pays est obligatoire !'
                            name='pays'
                            value={pays}
                            onChange={(e) => onChange(e)}
              
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-primary mr-2'>
                      Ajouter Fournisseur
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Fournisseur.propTypes = {
  addFournisseur: PropTypes.func.isRequired,
  isAdded: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAdded: state.fournisseur.isAdded,
});

export default connect(mapStateToProps, { addFournisseur })(Fournisseur);
