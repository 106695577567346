import { Redirect } from 'react-router-dom';
import React, { useState,useRef, Fragment } from 'react';
import MyNavbar from '../layout/navbar';
import { connect } from 'react-redux';
import { addLot } from '../../actions-services/demands';
import { setAlert } from '../../actions-services/alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Alert from '../layout/alert';
import Spinner from '../layout/Spinner';

const Lot = ({
  isAdded,
  fournisseurs,
  produits,
  addLot,
  match,
  demande: { achats, loading },
}) => {
  //const [state, setState] = useState(initialState);Hooks


  const [formData, setFormData] = useState({
    counter: 0,
    produit_name: '',
    code_lot: '',
    lot_added: false,
    products_values: [
      {
        product_id: { value: '', error: '' },
        quantite: { value: '', error: '' },
        futs: { value: '', error: '' },
      },
    ],
  });
  const {
    counter,
    produit_name,
    code_lot,
    products_values,
    lot_added
  } = formData;
  const quantity_lot = useRef(0);
  const [quantityLot, setQuantityLot] = useState(quantity_lot.current);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  }
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChangeInput = (e, counter_branch) => {
    const newValues = products_values;
    newValues[counter_branch][e.target.name].value = e.target.value;
    setFormData({ ...formData, products_values: newValues });
  };



  let list_codes = [];
  let list_achat_ids = [];
  let list_quantites = [];
  let list_products_ids = [];

  if ((achats !== null || achats !== []) && produit_name !== '') {
    if (match.params.state === 'calibrage') {
      for (let i = 0; i < achats.length; i++) {
        const achat = achats[i];
        const list_lots = achat.achat_lots;
        const list_produits = achat.produit_ids.split(',');
        const list_quantities = achat.quantites_current.split(',');
        const codes_achat = achat.code.split(',');
        //Loop on Products from achat
        for (let j = 0; j < list_produits.length; j++) {
          const id_product = list_produits[j];
          const achat_product_name = produits
            .filter(
              (produit, idx) =>
                (produit.produit_id === id_product &&
                produit.category_name === 'tout venant' &&
                produit.etat === 'trié') || 
                (produit.produit_id === id_product &&
                  produit.name ==='capres'&&
                  produit.category_name === 'tout venant')

            )
            .map((produit) => produit.name);

          if (achat_product_name[0] === produit_name) {
            const quantity_pr = list_quantities[j];
            if (quantity_pr !== '0') {
              list_codes.push(codes_achat[j]);
              list_achat_ids.push(achat.achat_id);
              list_quantites.push(quantity_pr);
              list_products_ids.push(id_product);
            }
          }
        }
        if (list_lots && list_lots!== []){
          for (let p = 0; p < list_lots.length; p++) {
            const lot_selected = list_lots[p];
            const list_ids_product = lot_selected.produit_ids.split(',');
            const list_quantites_product = lot_selected.quantites_current.split(
              ','
            );
            const list_codes_lot = lot_selected.code.split(',');
            for (let a = 0; a < list_ids_product.length; a++) {
              const id_pr_lot = list_ids_product[a];
              const lot_product_name = produits
                .filter(
                  (produit, idx) =>
                  (produit.produit_id === id_pr_lot &&
                    produit.category_name === 'tout venant' &&
                    produit.etat === 'trié') || 
                    (produit.produit_id === id_pr_lot &&
                      produit.name ==='capres'&&
                      produit.category_name === 'tout venant')
                )
                .map((produit) => produit.name);
              if (lot_product_name[0] === produit_name) {
                const quantity_lot_pr = list_quantites_product[a];
                if (quantity_lot_pr !== '0') {
                  list_codes.push(list_codes_lot[a]);
                  list_achat_ids.push(achat.achat_id);
                  list_quantites.push(quantity_lot_pr);
                  list_products_ids.push(id_pr_lot);
                }
              }
            }
          }
        }
      }
    }
    if (match.params.state === 'separation') {
      for (let i = 0; i < achats.length; i++) {
        const achat = achats[i];
        const list_lots = achat.achat_lots;
        const list_produits = achat.produit_ids.split(',');
        const list_quantities = achat.quantites_current.split(',');
        const codes_achat = achat.code.split(',');
        //Loop on Products from achat
        for (let j = 0; j < list_produits.length; j++) {
          const id_product = list_produits[j];
          const achat_product_name = produits
            .filter(
              (produit, idx) =>
                produit.produit_id === id_product &&
                produit.etat === 'non séparé'
            )
            .map((produit) => produit.name);

          if (achat_product_name[0] === produit_name) {
            const quantity_pr = list_quantities[j];
            if (quantity_pr !== '0') {
              list_codes.push(codes_achat[j]);
              list_achat_ids.push(achat.achat_id);
              list_quantites.push(quantity_pr);
              list_products_ids.push(id_product);
            }
          }
        }
        if (list_lots && list_lots!==[]){
          for (let p = 0; p < list_lots.length; p++) {
            const lot_selected = list_lots[p];
            const list_ids_product = lot_selected.produit_ids.split(',');
            const list_quantites_product = lot_selected.quantites_current.split(
              ','
            );
            const list_codes_lot = lot_selected.code.split(',');
            for (let a = 0; a < list_ids_product.length; a++) {
              const id_pr_lot = list_ids_product[a];
              const lot_product_name = produits
                .filter(
                  (produit, idx) =>
                    produit.produit_id === id_pr_lot &&
                    produit.etat === 'non séparé'
                )
                .map((produit) => produit.name);
              if (lot_product_name[0] === produit_name) {
                const quantity_lot_pr = list_quantites_product[a];
                if (quantity_lot_pr !== '0') {
                  list_codes.push(list_codes_lot[a]);
                  list_achat_ids.push(achat.achat_id);
                  list_quantites.push(quantity_lot_pr);
                  list_products_ids.push(id_pr_lot);
                }
              }
            }
          }
        }
      }
    }
    if (match.params.state === 'triage') {
      for (let i = 0; i < achats.length; i++) {
        const achat = achats[i];
        const list_lots = achat.achat_lots;
        const list_produits = achat.produit_ids.split(',');
        const list_quantities = achat.quantites_current.split(',');
        const codes_achat = achat.code.split(',');
        //Loop on Products from achat
        for (let j = 0; j < list_produits.length; j++) {
          const id_product = list_produits[j];
          const achat_product_name = produits
            .filter(
              (produit, idx) =>
                (produit.produit_id === id_product && produit.etat === 'séparé') ||
                (produit.produit_id === id_product && produit.etat === 'non trié')
            )
            .map((produit) => produit.name);

          if (achat_product_name[0] === produit_name) {
            const quantity_pr = list_quantities[j];
            if (quantity_pr !== '0') {
              list_codes.push(codes_achat[j]);
              list_achat_ids.push(achat.achat_id);
              list_quantites.push(quantity_pr);
              list_products_ids.push(id_product);
            }
          }
        }
        if (list_lots && list_lots!==[]){
          for (let p = 0; p < list_lots.length; p++) {
            const lot_selected = list_lots[p];
            const list_ids_product = lot_selected.produit_ids.split(',');
            const list_quantites_product = lot_selected.quantites_current.split(
              ','
            );
            const list_codes_lot = lot_selected.code.split(',');
            for (let a = 0; a < list_ids_product.length; a++) {
              const id_pr_lot = list_ids_product[a];
              const lot_product_name = produits
                .filter(
                  (produit, idx) =>
                    (produit.produit_id === id_pr_lot && produit.etat === 'séparé') ||
                    (produit.produit_id === id_pr_lot && produit.etat === 'non trié')
                )
                .map((produit) => produit.name);
              if (lot_product_name[0] === produit_name) {
                const quantity_lot_pr = list_quantites_product[a];
                if (quantity_lot_pr !== '0') {
                  list_codes.push(list_codes_lot[a]);
                  list_achat_ids.push(achat.achat_id);
                  list_quantites.push(quantity_lot_pr);
                  list_products_ids.push(id_pr_lot);
                }
              }
            }
          }
        }
      }
    }
  }

  //Search for achat id by the selected code
  let id_achat_selected = '';
  let category_selected = '';
  if (list_codes !== null && list_codes !== []) {
    for (let k = 0; k < list_codes.length; k++) {
      if (list_codes[k] === code_lot) {
        id_achat_selected = list_achat_ids[k];
        quantity_lot.current = list_quantites[k]
        category_selected = produits
          .filter((item) => item.produit_id === list_products_ids[k])
          .map((produit) => {
            return produit.category_name;
          });
      }
    }
  }

  const values = products_values;
  let produit_ids = '';
  let quantites = '';
  let futs = '';
  let dechets = '';

  for (let i = 0; i < values.length; i++) {
    const category_name_product = produits
      .filter((item) => item.produit_id === values[i].product_id.value)
      .map((produit) => {
        return produit.category_name;
      });
    if (category_name_product[0] !== 'déchets') {
      if (i === values.length - 1) {
        produit_ids = produit_ids + values[i].product_id.value;
        quantites = quantites + values[i].quantite.value;
        futs = futs + values[i].futs.value;
      } else {
        produit_ids = produit_ids + values[i].product_id.value + ',';
        quantites = quantites + values[i].quantite.value + ',';
        futs = futs + values[i].futs.value + ',';
      }
    } else {
      dechets = values[i].quantite.value;
    }
  }
  const handleAddModal = async (e) =>{
    e.preventDefault();
    handleShow();
  };
  const handleAdd = async (e) =>{
    e.preventDefault();
    const initialValue = 0;
    const sumWithInitial = products_values.map((values)=> values.quantite.value).reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue),
      initialValue
    );
    let somme_diff = 0;
    if(sumWithInitial - parseFloat(quantity_lot.current) > 0){
      somme_diff = sumWithInitial - parseFloat(quantity_lot.current)
    }else{
      setFormData({ ...formData, lot_added: true });
    }
    addLot(id_achat_selected, produit_ids, quantites, dechets, futs, code_lot,somme_diff);
  };

  let list_names = []
  if(match.params.state ==='calibrage'){
    list_names = produits.map((product) => {
      return product.name;
    });
  }
  if(match.params.state==='separation'){
    list_names = produits.filter((produit,idx)=>produit.etat ==='séparé').map((produit)=>{return produit.name})
  }
  if(match.params.state==='triage'){
    list_names = produits.filter((produit,idx)=>produit.etat ==='trié').map((produit)=>{return produit.name})
  }

  let codes_options = list_codes.map((code, idx) => (
    <option value={code}>
      {code} {' Quantité lot en Kg: '} {list_quantites[idx]}
    </option>
  ));
  let produits_options = list_names
    .filter((name, idx) => list_names.indexOf(name) === idx)
    .map((name) => <option value={name}>{name}</option>);
  let list_produits_ids_data = products_values.map((valeur) => {
    return valeur.product_id.value;
  });

  let categories_options = [];
  if (match.params.state === 'calibrage') {
    categories_options = produits
      .filter(
        (item, idx) =>
          list_produits_ids_data.includes(item.produit_id) === false &&
          ((item.name === produit_name && item.etat === 'non séparé') ||
          (item.name === produit_name && item.etat === 'non trié') ||
            (item.name === produit_name && item.category_name === 'déchets') ||
            (item.name === produit_name && item.category_name === '+21') ) && item.category_name !== category_selected[0]
      )
      .map((produit) => (
        <option value={produit.produit_id}>{produit.category_name}</option>
      ));
  }
  if (match.params.state === 'separation') {
    categories_options = produits
      .filter(
        (item, idx) =>
          list_produits_ids_data.includes(item.produit_id) === false &&
          ((item.name === produit_name &&
            item.etat === 'séparé' &&
            item.category_name === category_selected[0]) ||
            (item.name === produit_name && item.category_name === 'déchets'))
      )
      .map((produit) => (
        <option value={produit.produit_id}>{produit.category_name}</option>
      ));
  }
  if (match.params.state === 'triage') {
    categories_options = produits
      .filter(
        (item, idx) =>
          list_produits_ids_data.includes(item.produit_id) === false &&
          ((item.name === produit_name &&
            item.etat === 'trié' &&
            item.category_name === category_selected[0]) ||
            (item.name === produit_name && item.category_name === 'déchets'))
      )
      .map((produit) => (
        <option value={produit.produit_id}>{produit.category_name}</option>
      ));
  }
  const getCategoryName = (id_produit) => {
    return produits
      .filter((item, idx) => item.produit_id === id_produit)
      .map((produit) => {
        return produit.category_name;
      });
  };

  const renderBranch = (counter_branch) => {
    return (
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <div className='header-title'>
            <h4 className='card-title'>
              Produit numéro {' ' + counter_branch}
            </h4>
          </div>
          <button
            type='button'
            class='ml-2 mb-1 close'
            onClick={() => deleteBranch(counter_branch)}
          >
            <span aria-hidden='true'>×</span>
          </button>
        </div>
        <div className='card-body'>
          <p className='mb-2'>Informations</p>

          <div className='form-row'>
            <div className='col-sm'>
              <div className='form-group'>
                <label>Catégorie produit *</label>
                {products_values[counter_branch - 1].product_id.value === '' ? (
                  <select
                    name='product_id'
                    value={products_values[counter_branch - 1].product_id.value}
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Choisir une catégorie
                    </option>
                    {categories_options}
                  </select>
                ) : (
                  <select
                    name='product_id'
                    value={products_values[counter_branch - 1].product_id.value}
                    className='form-control'
                  >
                    <option
                      value={
                        products_values[counter_branch - 1].product_id.value
                      }
                      disabled
                    >
                      {getCategoryName(
                        products_values[counter_branch - 1].product_id.value
                      )}
                    </option>
                  </select>
                )}
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label>Quantité en Kg *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Entrer la quantité'
                  data-errors='La quantité est obligatoire !'
                  name='quantite'
                  value={products_values[counter_branch - 1].quantite.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label>Fûts "exp:50/180" </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Entrer la quantité'
                  data-errors='La quantité est obligatoire !'
                  name='futs'
                  value={products_values[counter_branch - 1].futs.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderBranches = () => {
    const result = [];
    for (let i = 0; i <= counter; i++) {
      result.push(renderBranch(i + 1));
    }
    return result;
  };
  const deleteBranch = (counter_branch) => {
    let newValues = products_values;
    newValues.splice(counter_branch - 1, 1);
    if (counter - 1 > 0 || counter - 1 === 0) {
      setFormData({
        ...formData,
        counter: counter - 1,
        products_values: newValues,
      });
    } else {
      setFormData({
        ...formData,
        counter: 0,
        products_values: [
          {
            product_id: { value: '', error: '' },
            quantite: { value: '', error: '' },
            futs: { value: '', error: '' },
          },
        ],
      });
    }
  };
  const appendDiv = () => {
    setFormData({
      ...formData,
      counter: counter + 1,
      products_values: [
        ...products_values,
        {
          product_id: { value: '', error: '' },
          quantite: { value: '', error: '' },
          futs: { value: '', error: '' },
        },
      ],
    });
  };

  if (isAdded) {
    return <Redirect to='/dashboard' />;
  }
  return (
    <Fragment>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid add-form-list'>
          {(achats === null || achats === []) && loading === true ? (
            <Spinner />
          ) : (
            <div className='row'>
              <div className='col-sm-12'>
                <div className='card'>
                  <div className='card-header d-flex justify-content-between'>
                    <div className='header-title'>
                      <h4 className='card-title'>
                        Ajouter Lot / {match.params.state.toUpperCase()}
                      </h4>
                    </div>
                  </div>
                  <div className='card-body'>
                    <form onSubmit={(e) => handleAddModal(e)}>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <label>Nom produit *</label>

                            <select
                              name='produit_name'
                              value={produit_name}
                              onChange={(e) => onChange(e)}
                              className='form-control'
                            >
                              <option value='' disabled>
                                Choisir un produit
                              </option>
                              {produits_options}
                            </select>
                            <div className='help-block with-errors'></div>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group'>
                            <label>Code du lot *</label>

                            <select
                              name='code_lot'
                              value={code_lot}
                              onChange={(e) => onChange(e)}
                              className='form-control'
                            >
                              <option value='' disabled>
                                Choisir un code
                              </option>
                              {codes_options}
                            </select>
                            <div className='help-block with-errors'></div>
                          </div>
                        </div>
                        {renderBranches()}
                        <div className='col-sm-4 justify-content-center'>
                          <div className='form-group'>
                            <button
                              type='button'
                              className='btn btn-success btn-block'
                              onClick={() => appendDiv()}
                            >
                              <i className='las la-plus mr-2'></i>Produit{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                      <Alert/>
                      <button type='submit' className='btn btn-primary mr-2'>
                        Ajouter Lot
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal show={show} onHide={handleClose} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
        <Modal.Header>
          <Modal.Title>
            Résumé de {match.params.state.toUpperCase()} du lot {code_lot}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          {lot_added===true?(<Spinner/>):(
            <table class="table">
            <thead class="thead-dark">
               <tr>
                  <th scope="col">#</th>
                  <th scope="col">CODE PRODUIT</th>
                  <th scope="col">QUANTITE</th>
                  <th scope="col">FUTS</th>
               </tr>
            </thead>
            <tbody>
              {products_values.map((product,idx)=>(
                <tr>
                  <th scope="row">{idx}</th>
                  <td>{produits.filter((pr,id)=>pr.produit_id===product.product_id.value).map((pro)=>{return pro.code + ' ' + pro.category_code})}</td>
                  <td>{product.quantite.value} Kg</td>
                  <td>{product.futs.value}</td>
              </tr>
              ))}
            </tbody>
         </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Retour</Button>
          <Button variant="success" onClick={(e) => handleAdd(e)}>Ajouter</Button>
        </Modal.Footer>
      </Modal>
      </div>
    </Fragment>
  );
};
Lot.propTypes = {
  addLot: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired,
  fournisseurs: PropTypes.array.isRequired,
  produits: PropTypes.array.isRequired,
  demande: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  isAdded: state.stock.isAddedLot,
  fournisseurs: state.fournisseur.fournisseurs,
  produits: state.stock.products,
  demande: state.demande,
});

export default connect(mapStateToProps, { addLot })(Lot);
