export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SELECTED_PROFILE = 'SELECTED_PROFILE';
export const PROFILES_LOADED = 'PROFILES_LOADED';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ADD_EDUCATION = 'ADD_EDUCATION';
export const ADD_EXPERIECE = 'ADD_EXPERIENCE';
export const LOGOUT = 'LOGOUT';
export const GET_POSTS = 'GET_POSTS';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const GET_POST = 'GET_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE COMMENT';
export const SELECTED_POST = 'SELECTED_POST';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const GET_EDUCATION = 'GET_EDUCATION';
export const GET_EXPERIENCE = 'GET_EXPERIENCE';
export const IDS_EDUCATION = 'IDS_EDUCATION';
export const IDS_EXPERIENCE = 'IDS_EXPERIENCE';
export const SELECTED_EDUCATION = 'SELECTED_EDUCATION';
export const SELECTED_EXPERIENCE = 'SELECTED_EXPERIENCE';
export const STOCK_LOADED = 'STOCK_LOADED';
export const STOCK_ERROR = 'STOCK_ERROR';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_FOURNISSEUR = 'ADD_FOURNISSUER';
export const FOURNISSEURS_LOADED = 'FOURNISSEURS_LOADED';
export const GET_ERROR = 'GET_ERROR';
export const ADD_ERROR = 'ADD_ERROR';
export const ADD_DEMAND = 'ADD_DEMAND';
export const DEMANDS_LOADED = 'DEMANDS_LOADED';
export const DEMAND_LOADED = 'DEMAND_LOADED';
export const VENTES_LOADED = 'VENTES_LOADED';
export const ADD_VENTE = 'ADD_VENTE';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const ADD_LOT = 'ADD_LOT';
export const ADD_DEMAND_DATE = 'ADD_DEMAND_DATE';
export const ADD_DEMAND_DATE_ERROR = 'ADD_DEMAND_DATE_ERROR';
export const CLIENTS_LOADED = 'CLIENTS_LOADED';
export const ADD_CLIENT = 'ADD_CLIENT';
