import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {loadCategories } from '../../actions-services/produits';
import store from '../../store';
import MyNavbar from '../layout/navbar';
import CategoryItem from './categoryItem';
import CategoryActions from './categoryActions';

const Categories = ({ categories }) => {
  useEffect(() => {
    store.dispatch(loadCategories());
  }, [loadCategories]);
  return (
    <Fragment>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <CategoryActions />
            </div>
            {categories !== null &&
            categories !== undefined &&
            categories !== [] ? (
              <div>
                <CategoryItem />
              </div>
            ) : (
              <Fragment>
                <p>
                  Vous n'avez pas encore ajouté une catégorie, veuillez ajouter
                  une catégorie...
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.stock.categories,
});

export default connect(mapStateToProps)(Categories);
