import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import Demands from '../achats/achats';
import Ventes from '../ventes/ventes';
import AddDemand from '../forms/demand';
import AddVente from '../forms/vente';
import Lots from '../forms/lots';
import Products from '../products/products';
import Addproduct from '../forms/product';
import Addcategory from '../forms/category';
import Catgories from '../categories/categories';
import AddFournisseur from '../forms/fournisseur';
import AddClient from '../forms/client';
import Fournisseurs from '../fournisseurs/fournisseur';
import Clients from '../clients/clients';
import Demand from '../achat/Achat';
import Vente from '../vente/Vente';
import NotFound from '../layout/NotFound';
import PrivateRoute from '../routing/PrivateRoute';

const Routes = (props) => {
  return (
    <Switch>
      <PrivateRoute exact path='/produits' component={Products} />
      <PrivateRoute exact path='/produits/ajout' component={Addproduct} />
      <PrivateRoute exact path='/categories' component={Catgories} />
      <PrivateRoute exact path='/categories/ajout' component={Addcategory} />
      <PrivateRoute exact path='/dashboard' component={Dashboard} />
      <PrivateRoute exact path='/fournisseurs' component={Fournisseurs} />
      <PrivateRoute exact path='/clients' component={Clients} />
      <PrivateRoute
        exact
        path='/fournisseurs/ajout'
        component={AddFournisseur}
      />
      <PrivateRoute
        exact
        path='/clients/ajout'
        component={AddClient}
      />
      <PrivateRoute exact path='/achats' component={Demands} />
      <PrivateRoute exact path='/ventes' component={Ventes} />
      <PrivateRoute exact path='/achat/:id' component={Demand} />
      <PrivateRoute exact path='/vente/:id' component={Vente} />
      <PrivateRoute exact path='/lots-ajout/:state' component={Lots} />
      <PrivateRoute exact path='/achats/ajout' component={AddDemand} />
      <PrivateRoute exact path='/ventes/ajout' component={AddVente} />
    </Switch>
  );
};

export default Routes;
