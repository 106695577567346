import axios from 'axios';
import { setAlert } from './alert';
import {
  ADD_DEMAND,
  ADD_DEMAND_DATE,
  ADD_LOT,
  ADD_ERROR,
  ADD_DEMAND_DATE_ERROR,
  DEMANDS_LOADED,
  GET_ERROR,
  DEMAND_LOADED,
} from './types';
import { loadFournisseurs } from './fournisseurs';
//LOAD Stock
export const loadDemands = () => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/demands');
    dispatch({
      type: DEMANDS_LOADED,
      payload: res.data,
    });
    dispatch(loadFournisseurs())
  } catch (error) {
    dispatch({
      type: GET_ERROR,
    });
  }
};

export const getDemandByUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/demands/' + id.toString());
    dispatch({
      type: DEMAND_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
    });
  }
};

export const addDemand = (
  fournisseur_id,
  produit_ids,
  quantites,
  nbre_futs,
  regions,
  prices,
  dates_fermentation
) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    fournisseur_id,
    produit_ids,
    quantites,
    nbre_futs,
    regions,
    prices,
    dates_fermentation,
  });
  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/demands', body, config);
    console.log()
    dispatch({
      type: ADD_DEMAND,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
    dispatch({
      type: ADD_ERROR,
    });
  }
};
export const addDate = (achat_id, date_fermentation, index_produit) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    date_fermentation,
    index_produit,
  });
  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/demands/' + achat_id, body, config);
    dispatch({
      type: ADD_DEMAND_DATE,
      payload: res.data,
    });
    dispatch(loadDemands());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
    dispatch({
      type: ADD_DEMAND_DATE_ERROR,
    });
  }
};
export const addLot = (
  achat_id,
  produit_ids,
  quantite_produits,
  dechets,
  futs,
  code_lot_from,
  somme_diff
) => async (dispatch) => {
  if(somme_diff>0){
    dispatch(setAlert('la somme des quantités du produit est supérieure à la quantité du lot sélectionné.', 'danger'));
  }
  else{
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      produit_ids,
      quantite_produits,
      dechets,
      futs,
      code_lot_from,
    });
    try {
      const res = await axios.post('https://ciocap-server.herokuapp.com/api/demands/lots/' + achat_id, body, config);
      dispatch({
        type: ADD_LOT,
        payload: res.data,
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch(setAlert(error.msg, 'danger'));
        });
      }
      dispatch({
        type: ADD_ERROR,
      });
    }
  }
  
};
