import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import logout from '../../actions-services/auth/logout';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MyNavbar = ({logout,isAuthenticated, user:{email,status,isLoaded}}) => {
  const authLinks = (
    <ul id='iq-sidebar-toggle' className='iq-menu'>
      <li className='active'>
        <Link to='/dashboard'>
          <svg
            className='svg-icon'
            id='p-dash1'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9"/><path d="M9 22V12h6v10M2 10.6L12 2l10 8.6"/>
          </svg>
          <span className='ml-4'>Dashboard</span>
        </Link>
      </li>
      <li className=' '>
        <a
          href='#product'
          className='collapsed'
          data-toggle='collapse'
          aria-expanded='false'
        >
          <svg
            className='svg-icon'
            id='p-dash2'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <circle cx='9' cy='21' r='1'></circle>
            <circle cx='20' cy='21' r='1'></circle>
            <path d='M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6'></path>
          </svg>
          <span className='ml-4'>Produits</span>
          <svg
            className='svg-icon iq-arrow-right arrow-active'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='10 15 15 20 20 15'></polyline>
            <path d='M4 4h7a4 4 0 0 1 4 4v12'></path>
          </svg>
        </a>
        <ul
          id='product'
          className='iq-submenu collapse'
          data-parent='#iq-sidebar-toggle'
        >
          <li className=''>
            <Link to='/produits'>
              <i className='las la-minus'></i>
              <span>Liste Produit</span>
            </Link>
          </li>
          <li className=''>
            <Link to='/produits/ajout'>
              <i className='las la-minus'></i>
              <span>Ajouter Produit</span>
            </Link>
          </li>
        </ul>
      </li>
      <li className=' '>
        <a
          href='#category'
          className='collapsed'
          data-toggle='collapse'
          aria-expanded='false'
        >
          <svg
            className='svg-icon'
            id='p-dash2'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <rect x="3" y="3" width="7" height="7"></rect>
            <rect x="14" y="3" width="7" height="7"></rect>
            <rect x="14" y="14" width="7" height="7"></rect>
            <rect x="3" y="14" width="7" height="7"></rect>
          </svg>
          <span className='ml-4'>Catégories</span>
          <svg
            className='svg-icon iq-arrow-right arrow-active'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='10 15 15 20 20 15'></polyline>
            <path d='M4 4h7a4 4 0 0 1 4 4v12'></path>
          </svg>
        </a>
        <ul
          id='category'
          className='iq-submenu collapse'
          data-parent='#iq-sidebar-toggle'
        >
          <li className=''>
            <Link to='/categories'>
              <i className='las la-minus'></i>
              <span>Liste Catgroies</span>
            </Link>
          </li>
          <li className=''>
            <Link to='/categories/ajout'>
              <i className='las la-minus'></i>
              <span>Ajouter Catgorie</span>
            </Link>
          </li>
        </ul>
      </li>
      <li className=' '>
        <a
          href='#sale'
          className='collapsed'
          data-toggle='collapse'
          aria-expanded='false'
        >
          <svg
            className='svg-icon'
            id='p-dash4'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path d='M21.21 15.89A10 10 0 1 1 8 2.83'></path>
            <path d='M22 12A10 10 0 0 0 12 2v10z'></path>
          </svg>
          <span className='ml-4'>Ventes</span>
          <svg
            className='svg-icon iq-arrow-right arrow-active'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='10 15 15 20 20 15'></polyline>
            <path d='M4 4h7a4 4 0 0 1 4 4v12'></path>
          </svg>
        </a>
        <ul
          id='sale'
          className='iq-submenu collapse'
          data-parent='#iq-sidebar-toggle'
        >
          <li className=''>
            <Link to='/ventes'>
              <i className='las la-minus'></i>
              <span>Liste Ventes</span>
            </Link>
          </li>
          <li className=''>
            <Link to='/ventes/ajout'>
              <i className='las la-minus'></i>
              <span>Ajouter Vente</span>
            </Link>
          </li>
        </ul>
      </li>
      <li className=' '>
        <a
          href='#purchase'
          className='collapsed'
          data-toggle='collapse'
          aria-expanded='false'
        >
          <svg
            className='svg-icon'
            id='p-dash5'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <rect x='1' y='4' width='22' height='16' rx='2' ry='2'></rect>
            <line x1='1' y1='10' x2='23' y2='10'></line>
          </svg>
          <span className='ml-4'>Achats</span>
          <svg
            className='svg-icon iq-arrow-right arrow-active'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='10 15 15 20 20 15'></polyline>
            <path d='M4 4h7a4 4 0 0 1 4 4v12'></path>
          </svg>
        </a>
        <ul
          id='purchase'
          className='iq-submenu collapse'
          data-parent='#iq-sidebar-toggle'
        >
          <li className=''>
            <Link to='/achats'>
              <i className='las la-minus'></i>
              <span>Liste Achats</span>
            </Link>
          </li>
          <li className=''>
            <Link to='/achats/ajout'>
              <i className='las la-minus'></i>
              <span>Ajouter Achat</span>
            </Link>
          </li>
        </ul>
      </li>
      <li className=' '>
        <a
          href='#people3'
          className='collapsed'
          data-toggle='collapse'
          aria-expanded='false'
        >
          <svg
            className='svg-icon'
            id='p-dash8'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
            <circle cx='9' cy='7' r='4'></circle>
            <path d='M23 21v-2a4 4 0 0 0-3-3.87'></path>
            <path d='M16 3.13a4 4 0 0 1 0 7.75'></path>
          </svg>
          <span className='ml-4'>Fournisseurs</span>
          <svg
            className='svg-icon iq-arrow-right arrow-active'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='10 15 15 20 20 15'></polyline>
            <path d='M4 4h7a4 4 0 0 1 4 4v12'></path>
          </svg>
        </a>
        <ul
          id='people3'
          className='iq-submenu collapse'
          data-parent='#iq-sidebar-toggle'
        >
          <li className=''>
            <Link to='/fournisseurs'>
              <i className='las la-minus'></i>
              <span>Fournisseurs</span>
            </Link>
          </li>
          <li className=''>
            <Link to='/fournisseurs/ajout'>
              <i className='las la-minus'></i>
              <span>Ajouter Fournisseur</span>
            </Link>
          </li>
        </ul>
      </li>
      <li className=' '>
        <a
          href='#people2'
          className='collapsed'
          data-toggle='collapse'
          aria-expanded='false'
        >
          <svg
            className='svg-icon'
            id='p-dash8'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
           <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
           <circle cx="12" cy="7" r="4"></circle>
          </svg>
          <span className='ml-4'>Clients</span>
          <svg
            className='svg-icon iq-arrow-right arrow-active'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='10 15 15 20 20 15'></polyline>
            <path d='M4 4h7a4 4 0 0 1 4 4v12'></path>
          </svg>
        </a>
        <ul
          id='people2'
          className='iq-submenu collapse'
          data-parent='#iq-sidebar-toggle'
        >
          <li className=''>
            <Link to='/clients'>
              <i className='las la-minus'></i>
              <span>Clients</span>
            </Link>
          </li>
          <li className=''>
            <Link to='/clients/ajout'>
              <i className='las la-minus'></i>
              <span>Ajouter Client</span>
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );

  //Adminlinks
  const adminLinks = (
    <ul id='iq-sidebar-toggle' class='iq-menu'>
      <li class='active'>
        <a href='../backend/index.html' class='svg-icon'>
          <svg
            class='svg-icon'
            id='p-dash1'
            width='20'
            height='20'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z'></path>
            <polyline points='3.27 6.96 12 12.01 20.73 6.96'></polyline>
            <line x1='12' y1='22.08' x2='12' y2='12'></line>
          </svg>
          <span class='ml-4'>Dashboards</span>
        </a>
      </li>
    </ul>
  );
    return (
      <>
        <div className='iq-sidebar'>
          <div className='iq-sidebar-logo d-flex align-items-center justify-content-between'>
            <Link to='/dashboard' className='header-logo'>
              <img
                src='../assets/images/logo.png'
                className='img-fluid rounded-normal light-logo'
                alt='logo'
              />
              <h5 className='logo-title light-logo ml-3'>CIOCAP</h5>
            </Link>
            <div className='iq-menu-bt-sidebar ml-0'>
              <svg 
                className='las la-bars wrapper-menu'
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="#000000" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </div>
          </div>

          <div className='data-scrollbar'>
            <nav className='iq-sidebar-menu'>
              {isAuthenticated || isLoaded
                ? authLinks
                : adminLinks}
            </nav>
          </div>
        </div>
        <div className='iq-top-navbar'>
          <div className='iq-navbar-custom'>
            <nav className='navbar navbar-expand-lg navbar-light p-0'>
              
              <div className='iq-search-bar device-search'>
                <form action='#' className='searchbox'>
                  <a className='search-link' href='#'>
                    <i className='las la-search'></i>
                  </a>
                  <input
                    type='text'
                    className='text search-input'
                    placeholder='Cherchez ici...'
                  />
                </form>
              </div>
              <div className='d-flex align-items-center'>
                <div className='change-mode'>
                  <div className='custom-control custom-switch custom-switch-icon custom-control-inline'>
                    <div className='custom-switch-inner'>
                      <p className='mb-0'> </p>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        id='dark-mode'
                        data-active='true'
                      />
                      <label
                        className='custom-control-label'
                        htmlFor='dark-mode'
                        data-mode='toggle'
                      >
                        <span className='switch-icon-left'>
                          <i className='a-left ri-moon-clear-line'></i>
                        </span>
                        <span className='switch-icon-right'>
                          <i className='a-right ri-sun-line'></i>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-label='Toggle navigation'
                >
                  <i className='ri-menu-3-line'></i>
                </button>
                <div
                  className='collapse navbar-collapse'
                  id='navbarSupportedContent'
                >
                  <ul className='navbar-nav ml-auto navbar-list align-items-center'>
                    <li className='nav-item nav-icon search-content'>
                      <a
                        href='#'
                        className='search-toggle rounded'
                        id='dropdownSearch'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <i className='ri-search-line'></i>
                      </a>
                      <div
                        className='iq-search-bar iq-sub-dropdown dropdown-menu'
                        aria-labelledby='dropdownSearch'
                      >
                        <form action='#' className='searchbox p-2'>
                          <div className='form-group mb-0 position-relative'>
                            <input
                              type='text'
                              className='text search-input font-size-12'
                              placeholder='type here to search...'
                            />
                            <a href='#' className='search-link'>
                              <i className='las la-search'></i>
                            </a>
                          </div>
                        </form>
                      </div>
                    </li>

                    <li className='nav-item nav-icon dropdown caption-content'>
                      <a
                        href='#'
                        className='search-toggle dropdown-toggle'
                        id='dropdownMenuButton4'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <img
                          src='../assets/images/page-img/user_img.jpg'
                          className='img-fluid rounded'
                          alt='user'
                        />
                      </a>
                      <div
                        className='iq-sub-dropdown dropdown-menu'
                        aria-labelledby='dropdownMenuButton'
                      >
                        <div className='card shadow-none m-0'>
                          <div className='card-body p-0 text-center'>
                            <div className='media-body profile-detail text-center'>
                              <img
                                src='../assets/images/page-img/profile-bg.jpg'
                                alt='profile-bg'
                                className='rounded-top img-fluid mb-4'
                              />
                              <img
                                src='../assets/images/page-img/user_img.jpg'
                                alt='profile-img'
                                className='rounded profile-img img-fluid avatar-70'
                              />
                            </div>
                            <div className='p-3'>
                              <h5 className='mb-1'>{email}</h5>
                              <p className='mb-0'>{status}</p>
                              <div className='d-flex align-items-center justify-content-center mt-3'>
                                <Link
                                  className='btn border'
                                  to='/'
                                  onClick={async (event) => {
                                    event.preventDefault();
                                    logout();
                                    window.location = '/';
                                  }}
                                >
                                  Se déconnecter
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  
}
MyNavbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
});

export default connect(mapStateToProps, { logout })(MyNavbar);

/*
<ul>
      <Link
        to='/'
        onClick={async (event) => {
          event.preventDefault();
          this.props.Logout();
          window.location = '/';
        }}
      >
        Logout
      </Link>
</ul>



<ul>
      <Link to='/login'>Sign In</Link>
</ul>
*/
