import {
    CLIENTS_LOADED,
    ADD_CLIENT,
    GET_ERROR,
    ADD_ERROR,
  } from '../actions-services/types';
  
  const initialState = {
    loading: true,
    clients: [],
    errors: [],
    new_client: null,
    isAdded: false,
  };
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CLIENTS_LOADED:
        localStorage.setItem('clients', payload.clients);
        return {
          ...state,
          ...payload,
          loading: false,
        };
      case ADD_CLIENT:
        return {
          ...state,
          new_client: payload.msg,
          loading: false,
          isAdded: true,
        };
      case GET_ERROR:
        return {
          ...state,
          loading: true,
        };
      case ADD_ERROR:
        localStorage.setItem('errors', payload);
        return {
          ...state,
          ...payload,
          loading: false,
        };
      default:
        return state;
    }
  }