import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ProductItem = ({ products }) => {
  const [formData, setFormData] = useState({
    nom: 'Tous',
    categorie: 'Tous',
    state: 'Tous',
    entries: '10',
    entries_debut: '0',
    current_entries: '10',
    count: 1,
  });
  let {
    nom,
    categorie,
    state,
    entries_debut,
    current_entries,
    entries,
    count,
  } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      entries_debut: '0',
      count: 1,
      [e.target.name]: e.target.value,
      current_entries: e.target.value,
      nom: nom,
      categorie: categorie,
      state: state,
    });
  };
  const onSelect = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (nom === 'Tous') {
      categorie = 'Tous';
      state = 'Tous';
    }
    if (categorie === 'Tous') {
      state = 'Tous';
    }
  };
  const nextClick = (e) => {
    e.preventDefault();
    let prev = parseInt(entries_debut) + parseInt(current_entries);
    let next = parseInt(entries) + parseInt(current_entries);//40 (30+10) 20 inf 30 true
    if (filtered_product.length < next) {
      if(prev>filtered_product.length){
      
      }else{
        if (
          parseInt(entries) - parseInt(entries_debut) < parseInt(entries)||
          filtered_product.length < parseInt(current_entries)
        ) {
          setFormData({
            entries_debut: prev.toString(),
            entries: filtered_product.length,
            count: count + 1,
            current_entries: current_entries,
            nom: nom,
            categorie: categorie,
            state: state,
          });
        }
      }
      
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count + 1,
        current_entries: current_entries,
        nom: nom,
        categorie: categorie,
        state: state,
      });
    }
  };
  const previousClick = (e) => {
    e.preventDefault();
    let step = parseInt(entries) - parseInt(entries_debut); //11 - 10 = 1; 28 - 25 = 3//
    let next = parseInt(entries) - step; // 11 - 1 = 10; 28 - 3 = 25
    let prev = next - parseInt(current_entries); // 10 - 10 = 9; 25 - 3 = 22;  50 - 3 = 47;
    console.log(step, next, prev, current_entries);
    if (prev < 0) {
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count - 1,
        current_entries: current_entries,
        nom: nom,
        categorie: categorie,
        state: state,
      });
    }
  };

  let list_names = [];
  let list_categories = [];
  let list_etat = [];
  if (nom === 'Tous') {
    categorie = 'Tous';
    state = 'Tous';
  }
  if (categorie === 'Tous') {
    state = 'Tous';
  }
  list_names = products.map((product) => {
    return product.name;
  });
  let product_option = list_names
    .filter((name, idx) => list_names.indexOf(name) === idx)
    .map((product_name) => (
      <option value={product_name}>{product_name}</option>
    ));

  for (let i = 0; i < products.length; i++) {
    let product = products[i];
    if (nom === product.name && product.category_name!=='déchets') {
      list_categories.push(product.category_name);
    }
  }
  let categories_options = list_categories
    .filter((category, idx) => list_categories.indexOf(category) === idx)
    .map((category) => <option value={category}>{category}</option>);

  for (let i = 0; i < products.length; i++) {
    let product = products[i];
    if (categorie === product.category_name) {
      list_etat.push(product.etat);
    }
  }
  let etat_options = list_etat.filter((name, idx) => list_etat.indexOf(name) === idx).map((etat) => (
    <option value={etat}>{etat}</option>
  ));

  let filtered_product = [];
  if (nom === 'Tous' && categorie === 'Tous' && state === 'Tous') {
    filtered_product = products.filter((item, idx) => item.category_name!=='déchets');
  } else {
    if (categorie === 'Tous' && state === 'Tous') {
      filtered_product = products.filter((item, idx) => item.name === nom && item.category_name!=='déchets');
    } else {
      if (state === 'Tous') {
        filtered_product = products.filter(
          (item, idx) => item.name === nom && item.category_name === categorie
        );
      } else {
        filtered_product = products.filter(
          (item, idx) =>
            item.name === nom &&
            item.category_name === categorie &&
            item.etat === state
        );
      }
    }
  }

  const products_table = filtered_product
    .filter(
      (item, idx) => parseInt(entries_debut) <= idx && idx < parseInt(entries)
    )
    .map((product) => (
      <tr key={product.produit_id}>
        <td>
          <div className='d-flex align-items-center'>
            <div>
              {product.name.toUpperCase()}
            </div>
          </div>
        </td>
        <td>{product.category_name.toUpperCase()}</td>
        <td>{product.etat.toUpperCase()}</td>
        <td>{product.quantite + ' Kg'}</td>

        <td>
          <div className='d-flex align-items-center list-action'>
            <a
              className='badge badge-info mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='View'
              href='#'
            >
              <i className='ri-eye-line mr-0'></i>
            </a>
            <a
              className='badge bg-success mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='Edit'
              href='#'
            >
              <i className='ri-pencil-line mr-0'></i>
            </a>
            <a
              className='badge bg-warning mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='Delete'
              href='#'
            >
              <i className='ri-delete-bin-line mr-0'></i>
            </a>
          </div>
        </td>
      </tr>
    ));

  return (
    <div className='col-lg-12'>
      <div className='table-responsive rounded mb-3'>
        <div className='dataTables_wrapper no-footer'>
          <div className='dataTables_length'>     
            <div>
              <label>
                Nom produit :
                <select
                  name='nom'
                  value={nom}
                  onChange={(e) => onSelect(e)}
                  className=''
                >
                  <option value='Tous'>Tous les produits</option>
                  {product_option}
                </select>
              </label>
              <label>
                Nom catégorie :
                <select
                  name='categorie'
                  value={categorie}
                  onChange={(e) => onSelect(e)}
                  className=''
                >
                  <option value='Tous'>Tous les catégories</option>
                  {categories_options}
                </select>
              </label>
              <label>
                Etat :
                <select
                  name='state'
                  value={state}
                  onChange={(e) => onSelect(e)}
                  className=''
                >
                  <option value='Tous'>Tous les états</option>
                  {etat_options}
                </select>
              </label>
            </div>
          </div>

          <table
            className='data-table table mb-0 tbl-server-info dataTable no-footer'
            role='grid'
          >
            <thead className='bg-white text-uppercase'>
              <tr className='ligth ligth-data'>
                <th>Produit</th>
                <th>Catégorie</th>
                <th>État</th>
                <th>Quantité</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className='ligth-body'>{products_table}</tbody>
          </table>
          <div className='dataTables_info' role='status'>
            Affichage de {entries_debut} à {entries} entrées
          </div>
          <div className='dataTables_paginate paging_simple_numbers'>
            <a
              className='paginate_button previous disabled'
              onClick={(e) => previousClick(e)}
            >
              Précédent
            </a>
            <span>
              <a className='paginate_button current'>{count}</a>
            </span>
            <a
              className='paginate_button next disabled'
              onClick={(e) => nextClick(e)}
            >
              Suivant
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductItem.propTypes = {
  products: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  products: state.stock.products,
});

export default connect(mapStateToProps)(ProductItem);
