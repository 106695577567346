import {
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_FAIL,
} from '../actions-services/types';

const initialState = {
  _id: '',
  email: '',
  name: '',
  status: '',
  avatar: '',
  isLoaded: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      localStorage.setItem('_id', payload.utilisateur_id);
      localStorage.setItem('email', payload.email);
      localStorage.setItem('name', payload.name);
      localStorage.setItem('status', payload.role);
      localStorage.setItem('avatar', payload.avatar);
      return {
        ...state,
        ...payload,
        isLoaded: true,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('_id');
      localStorage.removeItem('email');
      localStorage.removeItem('name');
      localStorage.removeItem('status');
      localStorage.removeItem('avatar');
      return {
        ...state,
        isLoaded: false,
      };
    default:
      return state;
  }
}
