import axios from 'axios';
import { setAlert } from './alert';
import { ADD_VENTE, ADD_ERROR, VENTES_LOADED, GET_ERROR } from './types';

//LOAD Stock
export const loadVentes = () => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/ventes');
    dispatch({
      type: VENTES_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
    });
  }
};
export const addVente = ({client_id,
  produit_ids,
  quantites,
  nbre_futs,
  codes_from,
  achat_ids}) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    client_id,
    produit_ids,
    quantites,
    nbre_futs,
    codes_from,
    achat_ids,
  });
  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/ventes', body, config);
    dispatch({
      type: ADD_VENTE,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => {
        dispatch(setAlert(error.msg, 'danger'));
      });
    }
    dispatch({
      type: ADD_ERROR,
    });
  }
};
