import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import Details from './details';
import { loadDemands } from '../../actions-services/demands';

import store from '../../store';
import MyNavbar from '../layout/navbar';
const Demands = ({ achats }) => {
 
  return (
    <Fragment>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <DashboardActions />
            </div>
            {achats !== null && achats !== undefined && achats !== [] ? (
              <div>
                <Details />
              </div>
            ) : (
              <Fragment>
                <p>
                  Vous n'avez pas encore ajouté un achat, veuillez ajouter
                  un achat...
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Demands.propTypes = {
  achats: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  achats: state.demande.achats,
});

export default connect(mapStateToProps)(Demands);
