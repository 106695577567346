import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../layout/navbar';
import Spinner from '../layout/Spinner'
import { loadProducts } from '../../actions-services/produits';
import store from '../../store';

const Stock = ({ stock: { products,categories, loading },isAuthenticated,user:{isLoaded},fournisseur:{fournisseurs},demande:{achats} }) => {
  useEffect(() => {
    store.dispatch(loadProducts());
  }, [loadProducts]);

  return (
    <Fragment>
      {fournisseurs !== [] ? (
        <Fragment>
        <Navbar />
          <div className='content-page'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-sm-12'>
                  <h3 className='mb-2'>Services</h3>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='card text-white bg-primary'>
                    <div className='card-body'>
                      <h4 className='card-title text-white'>Achats</h4>
                      <blockquote className='blockquote mb-0'>
                        <p className='font-size-14'>
                          Afin d'ajouter un nouvel achat, cliquez sur « Ajouter»;
                        </p>
                        <footer className='text-white font-size-12'>
                          <Link to='/achats/ajout' className='btn btn-dark mt-2'>
                            <i className='las la-plus mr-3'></i>Ajouter
                          </Link>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='card text-white bg-warning'>
                    <div className='card-body'>
                      <h4 className='card-title text-white'>Produits</h4>
                      <blockquote className='blockquote mb-0'>
                        <p className='font-size-14'>
                          Pour ajouter un nouveau produit cliquer sur « Ajouter »
                        </p>
                        <footer className='text-white font-size-12'>
                          <Link to='/produits/ajout' className='btn btn-dark mt-2'>
                            <i className='las la-plus mr-3'></i>Ajouter
                          </Link>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='card text-white bg-info'>
                    <div className='card-body'>
                      <h4 className='card-title text-white'>Catégories</h4>
                      <blockquote className='blockquote mb-0'>
                        <p className='font-size-14'>
                          Pour ajouter une nouvelle catégorie cliquer sur « Ajouter
                          »
                        </p>
                        <footer className='text-white font-size-12'>
                          <Link
                            to='/categories/ajout'
                            className='btn btn-dark mt-2'
                          >
                            <i className='las la-plus mr-3'></i>Ajouter
                          </Link>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='card text-white bg-secondary'>
                    <div className='card-body'>
                      <h4 className='card-title text-white'>Lots/Séparation</h4>
                      <blockquote className='blockquote mb-0'>
                        <p className='font-size-14'>
                          Afin de faire une séparation, cliquez sur le button «
                          Séparation »
                        </p>
                        <footer className='text-white font-size-12'>
                          <Link
                            to={`/lots-ajout/${'separation'}`}
                            className='btn btn-dark mt-2'
                          >
                            <i className='las la-plus mr-3'></i>Séparation
                          </Link>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='card text-white bg-success'>
                    <div className='card-body'>
                      <h4 className='card-title text-white'>Lots/Triage</h4>
                      <blockquote className='blockquote mb-0'>
                        <p className='font-size-14'>
                          Afin de faire un triage, cliquez sur le button « Triage »
                        </p>
                        <footer className='text-white font-size-12'>
                          <Link
                            to={`/lots-ajout/${'triage'}`}
                            className='btn btn-dark mt-2'
                          >
                            <i className='las la-plus mr-3'></i>Triage
                          </Link>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='card text-white bg-danger'>
                    <div className='card-body'>
                      <h4 className='card-title text-white'>Calibrage</h4>
                      <blockquote className='blockquote mb-0'>
                        <p className='font-size-14'>
                          Afin de faire le calibrage, cliquer sur le button «
                          Calibrage »
                        </p>
                        <footer className='text-white font-size-12'>
                          <Link
                            to={`/lots-ajout/${'calibrage'}`}
                            className='btn btn-dark mt-2'
                          >
                            <i className='las la-plus mr-3'></i>Calibrage
                          </Link>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ):(
        <Spinner/>
      ) }
      </Fragment>
  );
};

Stock.propTypes = {
  stock: PropTypes.object.isRequired,
  fournisseur: PropTypes.object.isRequired,
  demande: PropTypes.object.isRequired,
  user:PropTypes.object.isRequired,
  isAuthenticated:PropTypes.bool.isRequired

};

const mapStateToProps = (state) => ({
  user:state.user,
  stock: state.stock,
  fournisseur:state.fournisseur,
  demande:state.demande,
  isAuthenticated:state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Stock);
