import { Redirect } from 'react-router-dom';
import React, { useState, Fragment } from 'react';
import MyNavbar from '../layout/navbar';
import { connect } from 'react-redux';
import { addDemand } from '../../actions-services/demands';
import PropTypes from 'prop-types';

const Demand = ({ isAdded, fournisseurs, produits, addDemand }) => {
  //const [state, setState] = useState(initialState);Hooks
  const [formData, setFormData] = useState({
    counter: 0,
    fournisseur: '',
    products_values: [
      {
        product_name: { value: '', error: '' },
        etat: { value: '', error: '' },
        product_id: { value: '', error: '' },
        quantite: { value: '', error: '' },
        nbre_futs: { value: '', error: '' },
        region: { value: '', error: '' },
        price_uni: { value: '', error: '' },
        fermente: { value: false, error: '' },
        date_fermentation: { value: '', error: '' },
        remarque: { value: '', error: '' },
      },
    ],
    date_entree: '',
    isDemandAdded : false,
    back:false,
  });

  const { counter, fournisseur, products_values, date_entree, isDemandAdded,back } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleBack = () => {
    setFormData({ ...formData, back: true });
  };
  const handleChangeInput = (e, counter_lot) => {
    const newValues = products_values;
    newValues[counter_lot][e.target.name].value = e.target.value;
    setFormData({ ...formData, products_values: newValues });
  };

  const setChecked = (e, counter_lot) => {
    const newValues = products_values;
    newValues[counter_lot][e.target.name].value = !newValues[counter_lot][
      e.target.name
    ].value;
    setFormData({ ...formData, products_values: newValues });
  };

  const values = products_values;
  let produit_ids = '';
  let quantites = '';
  let nbre_futs = '';
  let regions = '';
  let prices = '';
  let dates_fermentation = '';
  for (let i = 0; i < values.length; i++) {
    if (i === values.length - 1) {
      produit_ids = produit_ids + values[i].product_id.value;
      quantites = quantites + values[i].quantite.value;
      nbre_futs = nbre_futs + values[i].nbre_futs.value;
      regions = regions + values[i].region.value;
      prices = prices + values[i].price_uni.value;
      if (
        values[i].date_fermentation.value !== '' &&
        values[i].date_fermentation.value !== null
      ) {
        dates_fermentation =
          dates_fermentation + values[i].date_fermentation.value;
      }
    } else {
      produit_ids = produit_ids + values[i].product_id.value + ',';
      quantites = quantites + values[i].quantite.value + ',';
      nbre_futs = nbre_futs + values[i].nbre_futs.value + ',';
      regions = regions + values[i].region.value + ',';
      prices = prices + values[i].price_uni.value + ',';
      if (
        values[i].date_fermentation.value !== '' &&
        values[i].date_fermentation.value !== null
      ) {
        dates_fermentation =
          dates_fermentation + values[i].date_fermentation.value + ',';
      } else {
        dates_fermentation = dates_fermentation + ',';
      }
    }
  }
  const handleAdd = async (e) => {
    e.preventDefault();
    setFormData({ ...formData, isDemandAdded: true });
    addDemand(
      fournisseur,
      produit_ids,
      quantites,
      nbre_futs,
      regions,
      prices,
      dates_fermentation
    );
  };


  //Select option for input data
  let fournisseur_options = fournisseurs.map((my_fournisseur) => (
    <option value={my_fournisseur.fournisseur_id}>{my_fournisseur.name}</option>
  ));
  let list_names = produits.map((product) => {
    return product.name;
  });
  let list_etats = produits
    .filter((item, idx) => item.etat !== 'déchets' && item.category_name !=='déchets' && item.name === products_values[counter].product_name.value)
    .map((product) => {
      return product.etat;
    });
  let produits_options = list_names
    .filter((name, idx) => list_names.indexOf(name) === idx)
    .map((name) => <option value={name}>{name}</option>);

  let etats_options = list_etats
    .filter((name, idx) => list_etats.indexOf(name) === idx)
    .map((name) => <option value={name}>{name}</option>);
  let categories_options = produits
    .filter(
      (item, idx) =>
        item.name === products_values[counter].product_name.value &&
        item.etat === products_values[counter].etat.value &&
        item.category_name !== 'déchets'
    )
    .map((produit) => (
      <option value={produit.produit_id}>{produit.category_name}</option>
    ));

  const getCategoryName = (id_produit) => {
    return produits
      .filter((item, idx) => item.produit_id === id_produit)
      .map((produit) => {
        return produit.category_name;
      });
  };
  //Function ro return a branch
  const renderBranch = (counter_branch) => {
    return (
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <div className='header-title'>
            <h4 className='card-title'>Produit {' ' + counter_branch}</h4>
          </div>
          <button
            type='button'
            class='ml-2 mb-1 close'
            onClick={() => deleteBranch(counter_branch)}
          >
            <span aria-hidden='true'>×</span>
          </button>
        </div>
        <div className='card-body'>
          <p className='mb-2'>Informations sur le produit</p>

          <div className='form-row'>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Nom produit *</label>
                {products_values[counter_branch - 1].product_name.value==='' ? (
                  <select
                    name='product_name'
                    value={
                      products_values[counter_branch - 1].product_name.value
                    }
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Choisir un produit
                    </option>
                    {produits_options}
                  </select>
                ):(
                  <select
                    name='product_name'
                    value={
                      products_values[counter_branch - 1].product_name.value
                    }
                    className='form-control'
                  >
                    <option value={products_values[counter_branch - 1].product_name.value} disabled>
                      {products_values[counter_branch - 1].product_name.value}
                    </option>
                  </select>
                )}
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Etat produit *</label>
                {products_values[counter_branch - 1].etat.value===''?(
                  <select
                    name='etat'
                    value={products_values[counter_branch - 1].etat.value}
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Choisir un état
                    </option>
                    {etats_options}
                  </select>
                ):(
                  <select
                    name='etat'
                    value={products_values[counter_branch - 1].etat.value}
                    className='form-control'
                  >
                    <option value={products_values[counter_branch - 1].etat.value} disabled>
                      {products_values[counter_branch - 1].etat.value}
                    </option>
                    
                  </select>
                )}
                  
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Catégorie produit *</label>
                {products_values[counter_branch - 1].product_id.value===''?(
                  <select
                    name='product_id'
                    value={products_values[counter_branch - 1].product_id.value}
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Choisir une catégorie
                    </option>
                    {categories_options}
                  </select>
                ):(
                  <select
                    name='product_id'
                    value={products_values[counter_branch - 1].product_id.value}
                    className='form-control'
                  >
                    <option value={products_values[counter_branch - 1].product_id.value} disabled>
                      {getCategoryName(products_values[counter_branch - 1].product_id.value)}
                    </option>
                  </select>
                )}
                  
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Quantité en Kg *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder="Entrer la quantité d'achat"
                  data-errors='La quantité est obligatoire !'
                  name='quantite'
                  value={products_values[counter_branch - 1].quantite.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Nombre de fûts *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Entrer le nombre de fûts'
                  data-errors='Le nombre de fûts est obligatoire !'
                  name='nbre_futs'
                  value={products_values[counter_branch - 1].nbre_futs.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Région *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Entrer la région du produit'
                  data-errors='La région est obligatoire !'
                  name='region'
                  value={products_values[counter_branch - 1].region.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Prix unitaire *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Entrer le prix unitaire du produit'
                  data-errors='Le prix unitaire est obligatoire !'
                  name='price_uni'
                  value={products_values[counter_branch - 1].price_uni.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-12'>
              <div className='checkbox d-inline-block mr-3'>
                <input
                  type='checkbox'
                  className='checkbox-input'
                  value={products_values[counter_branch - 1].fermente.value}
                  name='fermente'
                  onChange={(e) => setChecked(e, counter_branch - 1)}
                />
                <label for='checkbox1'> Produit fermenté ?</label>
              </div>

              {products_values[counter_branch - 1].fermente.value == true ? (
                <div className='form-group'>
                  <label>Date Fermentation *</label>
                  <input
                    type='date'
                    className='form-control'
                    placeholder='Entrer la date de fermentation'
                    data-errors='La date est obligatoire !'
                    name='date_fermentation'
                    value={
                      products_values[counter_branch - 1].date_fermentation
                        .value
                    }
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    required
                  />
                  <div className='help-block with-errors'></div>
                </div>
              ) : (
                <Fragment>
                  <p></p>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBranches = () => {
    const result = [];
    for (let i = 0; i <= counter; i++) {
      result.push(renderBranch(i + 1));
    }
    return result;
  };
  const deleteBranch = (counter_branch) => {
    let newValues = products_values;
    newValues.splice(counter_branch - 1, 1);
    if (counter - 1 > 0 || counter - 1 == 0) {
      setFormData({
        ...formData,
        counter: counter - 1,
        products_values: newValues,
      });
    } else {
      setFormData({
        ...formData,
        counter: 0,
        products_values: [
          {
            product_name: { value: '', error: '' },
            etat: { value: '', error: '' },
            product_id: { value: '', error: '' },
            quantite: { value: '', error: '' },
            nbre_futs: { value: '', error: '' },
            region: { value: '', error: '' },
            price_uni: { value: '', error: '' },
            fermente: { value: false, error: '' },
            date_fermentation: { value: '', error: '' },
            remarque: { value: '', error: '' },
          },
        ],
      });
    }
  };
  const appendDiv = () => {
    setFormData({
      ...formData,
      counter: counter + 1,
      products_values: [
        ...products_values,
        {
          product_name: { value: '', error: '' },
          etat: { value: '', error: '' },
          product_id: { value: '', error: '' },
          quantite: { value: '', error: '' },
          nbre_futs: { value: '', error: '' },
          region: { value: '', error: '' },
          price_uni: { value: '', error: '' },
          fermente: { value: false, error: '' },
          date_fermentation: { value: '', error: '' },
          remarque: { value: '', error: '' },
        },
      ],
    });
  };

  if(back){
    return <Redirect to='/achats'/>;
  }
  return (
    <>
      <MyNavbar />
      <div className='content-page'>
        
        {
          isDemandAdded && isAdded == true ? (
            <div className="card">
                  <div className="card-body">
                     <div className="alert alert-success" role="alert">
                        <div className="iq-alert-text">Votre ajout de demande a été accompli avec <b>succès</b> !</div>
                     </div>
                     <button className="btn btn-primary mt-2" style={{ cursor: "pointer" }} type="button" onClick={() => handleBack()} >Liste des achats</button>
                  </div>
            </div>
          ) : isDemandAdded == true && isAdded == false ? (
            <div className="card">
                  <div className="card-body">
                     <div className="alert alert-primary" role="alert">
                        <div className="iq-alert-text">Votre demande est en cours de <b>traitement</b> !</div>
                     </div>
                  </div>
            </div>
          ) :  (
            <div className='container-fluid add-form-list'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Ajouter Achat</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={(e) => handleAdd(e)}>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Nom fournisseur *</label>
                          <select
                            name='fournisseur'
                            value={fournisseur}
                            onChange={(e) => onChange(e)}
                            className='form-control'
                          >
                            <option value='' disabled>
                              Choisir un fournisseur
                            </option>
                            {fournisseur_options}
                          </select>
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      {renderBranches()}
                      <div className='col-sm-4 justify-content-center'>
                        <div className='form-group'>
                          <button
                            type='button'
                            className='btn btn-success btn-block'
                            onClick={() => appendDiv()}
                          >
                            <i className='las la-plus mr-2'></i>Produit{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-primary mr-2'>
                      Ajouter Achat
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        }
      </div>
    </>
  );
};
Demand.propTypes = {
  addDemand: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired,
  fournisseurs: PropTypes.array.isRequired,
  produits: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAdded: state.demande.isAdded,
  fournisseurs: state.fournisseur.fournisseurs,
  produits: state.stock.products,
});
export default connect(mapStateToProps, { addDemand })(Demand);
