import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { addClient } from '../../actions-services/clients';
import MyNavbar from '../layout/navbar';
import PropTypes from 'prop-types';

const Client = ({ addClient, isAdded }) => {
  const [formData, setFormData] = useState({
    nom: '',
    pays: '',
  });
  const { nom, pays } = formData;

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAdd = (e) => {
    e.preventDefault();
    addClient(nom, pays);
  };

  if (isAdded) {
    return <Redirect to='/clients' />;
  }
  return (
    <div>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid add-form-list'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Ajouter Client</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={(e) => handleAdd(e)}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Code du client</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le nom'
                            data-errors='Le nom est obligatoire !'
                            name='nom'
                            value={nom}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Pays *</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Entrer le pays'
                            data-errors='Le pays est obligatoire !'
                            name='pays'
                            value={pays}
                            onChange={(e) => onChange(e)}
                            required
                          />
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-primary mr-2'>
                      Ajouter Client
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Client.propTypes = {
  addClient: PropTypes.func.isRequired,
  isAdded: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAdded: state.client.isAdded,
});

export default connect(mapStateToProps, { addClient })(Client);
