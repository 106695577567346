import { Redirect } from 'react-router-dom';
import React, { useState,useRef, Fragment } from 'react';
import MyNavbar from '../layout/navbar';
import { connect } from 'react-redux';
import { addVente } from '../../actions-services/ventes';
import PropTypes from 'prop-types';

const Vente = ({ isAdded, clients, produits, addVente,achats }) => {
  //const [state, setState] = useState(initialState);Hooks
  const [formData, setFormData] = useState({
    counter: 0,
    client_id: '',
    products_values: [
      {
        product_id: { value: '', error: '' },
        quantite: { value: '', error: '' },
        nbre_futs: { value: '', error: '' },
        code_from: { value: '', error: '' },
        achat_id :{value:'',error:''},
        remarque: { value: '', error: '' },
      },
    ],

    date_sortie: '',
  });


  const {counter, client_id,products_values,date_sortie} = formData
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleChangeInput = (e, counter_lot) => {
    const newValues = products_values;
    newValues[counter_lot][e.target.name].value = e.target.value;
    if(e.target.name ==='code_from'){
      let id_achat_selected = '';
      if (list_codes !== null && list_codes !== []) {
        for (let k = 0; k < list_codes.length; k++) {
            if (list_codes[k] === e.target.value) {
              id_achat_selected = list_achat_ids[k];
            }
        }
      }
      newValues[counter_lot]['achat_id'].value = id_achat_selected;
    }
    if(e.target.name==='quantite'){
      let quantite_lot = '';
      if (list_codes !== null && list_codes !== []) {
        for (let k = 0; k < list_codes.length; k++) {
            if (list_codes[k] === newValues[counter_lot].code_from.value) {
              quantite_lot = list_quantites[k];
            }
        }
      }

      if(quantite_lot - e.target.value < 0 ){
        newValues[counter_lot]['quantite'].error = 'La quantité saisie est supérieure à la quantité du lot. Veuillez vérifier !';
      }
      else{
        newValues[counter_lot]['quantite'].error = '';
      }
    }
    setFormData({ ...formData, products_values: newValues });
  };
  

  const values = products_values;
  let produit_ids = '';
  let quantites = '';
  let nbre_futs = '';
  let codes_from = '';
  let achat_ids = '';
  for (let i = 0; i < values.length; i++) {
    if (i === values.length - 1) {
      produit_ids = produit_ids + values[i].product_id.value;
      quantites = quantites + values[i].quantite.value;
      nbre_futs = nbre_futs + values[i].nbre_futs.value;
      codes_from = codes_from + values[i].code_from.value;
      achat_ids = achat_ids + values[i].achat_id.value;
    } else {
      produit_ids = produit_ids + values[i].product_id.value + ',';
      quantites = quantites + values[i].quantite.value + ',';
      nbre_futs = nbre_futs + values[i].nbre_futs.value + ',';
      codes_from = codes_from + values[i].code_from.value + ',';
      achat_ids = achat_ids + values[i].achat_id.value + ',';
    }
  }

  const handleAdd = async (e) => {
    e.preventDefault();
    const list_errors = products_values.filter((item,idx)=> item.quantite.error !== '').map((item)=>item.quantite.error)
    if(list_errors.length===0){
      addVente({
        client_id,
        produit_ids,
        quantites,
        nbre_futs,
        codes_from,
        achat_ids
      });
    }
  };


  let list_codes = [];
  let list_achat_ids = [];
  let list_quantites = [];
  let list_products_ids = [];

  for (let i = 0; i < achats.length; i++) {
    const achat = achats[i];
    const list_lots = achat.achat_lots;
    const list_produits = achat.produit_ids.split(',');
    const list_quantities = achat.quantites_current.split(',');
    const codes_achat = achat.code.split(',');
    //Loop on Products from achat
    for (let j = 0; j < list_produits.length; j++) {
      const id_product = list_produits[j];
      const achat_product_name = produits
        .filter(
          (produit, idx) =>
            produit.produit_id === id_product &&
            produit.category_name !== 'tout venant' &&
            produit.etat === 'trié'
        )
        .map((produit) => produit.name);

      if (achat_product_name.length === 1) {
        const quantity_pr = list_quantities[j];
        if (quantity_pr !== '0') {
          list_codes.push(codes_achat[j])
          list_achat_ids.push(achat.achat_id);
          list_quantites.push(quantity_pr);
          list_products_ids.push(id_product);
        }
      }
    }
    for (let p = 0; p < list_lots.length; p++) {
      const lot_selected = list_lots[p];
      const list_ids_product = lot_selected.produit_ids.split(',');
      const list_quantites_product = lot_selected.quantites_current.split(
        ','
      );
      const list_codes_lot = lot_selected.code.split(',');
      for (let a = 0; a < list_ids_product.length; a++) {
        const id_pr_lot = list_ids_product[a];
        const lot_product_name = produits
          .filter(
            (produit, idx) =>
              produit.produit_id === id_pr_lot &&
              produit.category_name !== 'tout venant' &&
              produit.etat ==='trié'
          )
          .map((produit) => produit.name);
          
        if (lot_product_name.length === 1) {
          const quantity_lot_pr = list_quantites_product[a];
          if (quantity_lot_pr !== '0') {
            list_codes.push(list_codes_lot[a]);
            list_achat_ids.push(achat.achat_id);
            list_quantites.push(quantity_lot_pr);
            list_products_ids.push(id_pr_lot);
          }
        }
      }
    }
  }






let codes_options = list_codes.map((code, idx) => (
  <option value={code}>
    {code} {' Quantité lot en Kg: '} {list_quantites[idx]}
  </option>
));


  let client_options = clients.map((client) => (
    <option value={client.client_id}>{client.code}</option>
  ));

  

  const productsOptions = (code_lot_from,counter_) =>{
    //Search for achat id by the selected code
    let id_achat_selected = '';
    let id_product_selected = '';
    if (list_codes !== null && list_codes !== []) {
        for (let k = 0; k < list_codes.length; k++) {
            if (list_codes[k] === code_lot_from) {
              id_achat_selected = list_achat_ids[k];
              id_product_selected = list_products_ids[k];
            }
        }
    }
    return produits
    .filter((item, idx) => item.produit_id === id_product_selected)
    .map((product) =>  <option value={product.produit_id}>{product.name.toUpperCase() + ' ' + product.category_name.toUpperCase()}</option>);
}


  const renderBranch = (counter_branch) => {
    return (
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <div className='header-title'>
            <h4 className='card-title'>Produit {' ' + counter_branch}</h4>
          </div>
          <button
            type='button'
            class='ml-2 mb-1 close'
            onClick={() => deleteBranch(counter_branch)}
          >
            <span aria-hidden='true'>×</span>
          </button>
        </div>
        <div className='card-body'>
          <p className='mb-2'>Informations sur le produit</p>

          <div className='form-row'>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>A partir du lot *</label>

                
                  <select
                    name='code_from'
                    value={
                      products_values[counter_branch - 1].code_from.value
                    }
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Choisir un code
                    </option>
                    {codes_options}
                  </select>
               

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Nom produit *</label>

                
                  <select
                    name='product_id'
                    value={products_values[counter_branch - 1].product_id.value}
                    onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                    className='form-control'
                  >
                    <option value='' disabled>
                      Choisir un état
                    </option>
                    {productsOptions(products_values[counter_branch - 1].code_from.value,counter_branch)}
                  </select>
               

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Quantité en Kg *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder="Entrer la quantité d'achat"
                  data-errors='La quantité est obligatoire !'
                  name='quantite'
                  value={products_values[counter_branch - 1].quantite.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                {products_values[counter_branch-1].quantite.error !==''&& <div className='help-block with-errors' style={{color:'red'}}>{products_values[counter_branch-1].quantite.error}</div>}
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group'>
                <label>Nombre de fûts *</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Entrer le nombre de fûts'
                  data-errors='Le nombre de fûts est obligatoire !'
                  name='nbre_futs'
                  value={products_values[counter_branch - 1].nbre_futs.value}
                  onChange={(e) => handleChangeInput(e, counter_branch - 1)}
                  required
                />
                <div className='help-block with-errors'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBranches = () => {
    const result = [];
    for (let i = 0; i <= counter; i++) {
      result.push(renderBranch(i + 1));
    }
    return result;
  };
  const deleteBranch = (counter_branch) => {
    let newValues = products_values;
    newValues.splice(counter_branch - 1, 1);
    if (counter - 1 > 0 || counter - 1 == 0) {
      setFormData({
        ...formData,
        counter: counter - 1,
        products_values: newValues,
      });
    } else {
      setFormData({
        ...formData,
        counter: 0,
        products_values: [
          {
            product_id: { value: '', error: '' },
            quantite: { value: '', error: '' },
            nbre_futs: { value: '', error: '' },
            code_from: { value: '', error: '' },
            achat_id :{value:'',error:''},
            remarque: { value: '', error: '' },
          },
        ],
      });
    }
  };
  const appendDiv = () => {
    setFormData({
      ...formData,
      counter: counter + 1,
      products_values: [
        ...products_values,
        {
          product_id: { value: '', error: '' },
        quantite: { value: '', error: '' },
        nbre_futs: { value: '', error: '' },
        code_from: { value: '', error: '' },
        achat_id :{value:'',error:''},
        remarque: { value: '', error: '' },
        },
      ],
    });
  };

  if (isAdded) {
    return <Redirect to='/ventes' />;
  }
  return (
    <>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid add-form-list'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>Ajouter Vente</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <form onSubmit={(e) => handleAdd(e)}>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label>Nom client *</label>
                          <select
                            name='client_id'
                            value={client_id}
                            onChange={(e) => onChange(e)}
                            className='form-control'
                          >
                            <option value='' disabled>
                              Choisir un client
                            </option>
                            {client_options}
                          </select>
                          <div className='help-block with-errors'></div>
                        </div>
                      </div>
                      {renderBranches()}
                      <div className='col-sm-4 justify-content-center'>
                        <div className='form-group'>
                          <button
                            type='button'
                            className='btn btn-success btn-block'
                            onClick={() => appendDiv()}
                          >
                            <i className='las la-plus mr-2'></i>Produit{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-primary mr-2'>
                      Ajouter Vente
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Vente.propTypes = {
  addVente: PropTypes.func.isRequired,
  isAdded: PropTypes.bool.isRequired,
  clients: PropTypes.array.isRequired,
  achats : PropTypes.array.isRequired,
  produits: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  isAdded: state.vente.isAdded,
  clients: state.client.clients,
  achats : state.demande.achats,
  produits: state.stock.products,
});
export default connect(mapStateToProps, { addVente })(Vente);

