import axios from 'axios';
import { setAlert } from './alert';
import {
  ADD_FOURNISSEUR,
  ADD_ERROR,
  FOURNISSEURS_LOADED,
  GET_ERROR,
} from './types';

//LOAD Stock
export const loadFournisseurs = () => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/fournisseurss');
    dispatch({
      type: FOURNISSEURS_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
    });
  }
};
export const addFournisseur = (name, tel, email, region, pays) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ name, tel, email, region, pays });

  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/fournisseurss', body, config);
    dispatch({
      type: ADD_FOURNISSEUR,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.data;

    dispatch({
      type: ADD_ERROR,
      payload: errors,
    });
  }
};
