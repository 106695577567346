import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { loadClients } from '../../actions-services/clients';
import { connect } from 'react-redux';
import store from '../../store';
import InvoicePdf from '../reports/index'
const Details = ({ ventes, clients, produits }) => {
  useEffect(() => {
    store.dispatch(loadClients());
  }, [loadClients]);
  const [formData, setFormData] = useState({
    entries: '10',
    entries_debut: '0',
    current_entries: '10',
    count: 1,
  });
  const invoiceDataRef = useRef([]);
  const [invoiceData, setInvoiceData] = useState(invoiceDataRef.current);
  let { entries_debut, current_entries, entries, count } = formData;
  const [show, setShow] = useState(false);
  console.log(invoiceDataRef)
  const handleClose = () => setShow(false);
  const handleShow = (vente_id) => {
    let newInvoiceData = []
    for(let i =0;i<ventes.length;i++){
      const vente = ventes[i];
      if (vente.vente_id === vente_id){
        let items = [];
        let list_produits_ids = vente.produit_ids.split(',');
        let list_quantites = vente.quantites.split(',');
        let list_futs = vente.nbre_futs.split(',');
        let list_code_from = vente.code_from.split(',');
        for (let j=0;j<list_produits_ids.length;j++){
          items.push({sno:j+1,desc:produits
            .filter(
              (produit, idx) =>
                produit.produit_id === list_produits_ids[j]
            )
            .map((product) => {
              return product.name.toUpperCase() + ' '+product.category_name.toUpperCase()+  ' (' +product.code + ' ' + product.category_code + ' )'
              ;
            }),qty:list_quantites[j],rate:list_futs[j],region:list_code_from[j]})
        }
        newInvoiceData.push({id:vente_id,invoice_no:vente_id.slice(0, 5),description:'',balance:'',company:clients
        .filter(
          (client, idx) =>
            client.client_id === vente.client_id
        )
        .map((client) => {
          return client.code;
        }),email:'',phone:'',address:clients
        .filter(
          (client, idx) =>
            client.client_id === vente.client_id
        )
        .map((client) => {
          return client.pays + ',';
        }),trans_date:vente.date_sortie,due_date:'',items:items})

        invoiceDataRef.current = newInvoiceData
      }
    }
    setShow(true)};

  const onChange = (e) => {
    setFormData({
      ...formData,
      entries_debut: '0',
      count: 1,
      [e.target.name]: e.target.value,
      current_entries: e.target.value,
    });
  };

  const nextClick = (e) => {
    e.preventDefault();
    let prev = parseInt(entries_debut) + parseInt(current_entries);
    let next = parseInt(entries) + parseInt(current_entries);
    if (ventes.length < next) {
      if(prev>ventes.length){
      
      }else{
        if (
          parseInt(entries) - parseInt(entries_debut) < parseInt(entries) ||
          ventes.length < parseInt(current_entries)
        ) {
          setFormData({
            entries_debut: prev.toString(),
            entries: ventes.length,
            count: count + 1,
            current_entries: current_entries,
          });
        }
      }
      
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count + 1,
        current_entries: current_entries,
      });
    }
  };
  const previousClick = (e) => {
    e.preventDefault();
    let step = parseInt(entries) - parseInt(entries_debut); //11 - 10 = 1; 28 - 25 = 3//
    let next = parseInt(entries) - step; // 11 - 1 = 10; 28 - 3 = 25
    let prev = next - parseInt(current_entries); // 10 - 10 = 9; 25 - 3 = 22;  50 - 3 = 47;
    console.log(step, next, prev, current_entries);
    if (prev < 0) {
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count - 1,
        current_entries: current_entries,
      });
    }
  };

  let ventes_table = ventes
    .filter(
      (item, idx) => parseInt(entries_debut) <= idx && idx < parseInt(entries)
    )
    .map((vente) => (
      <tr key={vente.vente_id}>
        <td>
          <div className='d-flex align-items-center'>
            <div>
              {vente.vente_id.slice(0, 5)}
              <p className='mb-0'>
                <small>
                  {vente.date_sortie}
                </small>
              </p>
            </div>
          </div>
        </td>
        <td>
          {clients
            .filter(
              (client, idx) =>
                client.client_id === vente.client_id
            )
            .map((client) => {
              return client.code;
            })}
        </td>
        <td>
          <ul>
          {produits
            .filter((product, idx) =>
              vente.produit_ids.includes(product.produit_id)
            )
            .map((product) => (
              <li>{product.code + ' ' + product.category_code }</li>
            ))}
          </ul>
        </td>
        <td>
          <ul>
          {vente.quantites.split(',').map((quantity) => (
            <li>{quantity + ' Kg'}</li>
          ))}
          </ul>
        </td>
        <td>
          <ul>
          {vente.nbre_futs.split(',').map((fut) => (
            <li>{fut}</li>
          ))}
          </ul>
        </td>

        <td>
          <div className='d-flex align-items-center list-action'>
            <a
              className='badge bg-success mr-2'
              style={{cursor: 'pointer'}}
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='Edit'
              onClick={() => handleShow(vente.vente_id)}
            >
            <i className="las la-file-pdf" style={{fontSize:'30px'}}></i>            
            </a>

          </div>
        </td>
      </tr>
    ));

  return (
    <div className='col-lg-12'>
      <div className='table-responsive rounded mb-3'>
        <div className='dataTables_wrapper no-footer'>
          <table
            className='data-table table mb-0 tbl-server-info dataTable no-footer'
            role='grid'
          >
            <thead className='bg-white text-uppercase'>
              <tr className='ligth ligth-data'>
             
                <th>Réf.</th>
                <th>Code client</th>
                <th>Produits</th>
                <th>Quantité</th>
                <th>fûts</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className='ligth-body'>{ventes_table}</tbody>
          </table>
          <div className='dataTables_info' role='status'>
            Affichage de {entries_debut} à {entries} entrées
          </div>
          <div className='dataTables_paginate paging_simple_numbers'>
            <a
              className='paginate_button previous disabled'
              onClick={(e) => previousClick(e)}
            >
              Précédent
            </a>
            <span>
              <a className='paginate_button current'>{count}</a>
            </span>
            <a
              className='paginate_button next disabled'
              onClick={(e) => nextClick(e)}
            >
              Suivant
            </a>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
        <Modal.Header closeButton>
          <Modal.Title>Résumé de vente</Modal.Title>
        </Modal.Header>
        <Modal.Body >{invoiceDataRef.current !== [] && <InvoicePdf invoice={invoiceDataRef.current[0]} type='vente'/>}</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Details.propTypes = {
  ventes: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  produits: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  ventes: state.vente.ventes,
  clients: state.client.clients,
  produits: state.stock.products,
});

export default connect(mapStateToProps)(Details);
