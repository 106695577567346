import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions-services/auth';
import PropTypes from 'prop-types';

const Login = ({ login, isAuthenticated,isUserLoaded }) => {
  //const [state, setState] = useState(i,nitialState);Hooks
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitLogin = async (event) => {
    event.preventDefault();
    login({ email, password });
  };

  //REDIRECT if loggedIn
  if (isAuthenticated && isUserLoaded) {
    return <Redirect to='/dashboard' />;
  }

  return (

      <div className='wrapper'>
        <section className='login-content'>
          <div className='container'>
            <div className='row align-items-center justify-content-center height-self-center'>
              <div className='col-lg-8'>
                <div className='card auth-card'>
                  <div className='card-body p-0'>
                    <div className='d-flex align-items-center auth-content'>
                      <div className='col-lg-7 align-self-center'>
                        <div className='p-3'>
                          <h2 className='mb-2'>Se connecter</h2>
                          <p>Identifiez-vous</p>
                          <form onSubmit={(e) => onSubmitLogin(e)}>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <div className='floating-label form-group'>
                                  <input
                                    className='floating-input form-control'
                                    type='email'
                                    name='email'
                                    value={email}
                                    placeholder=' '
                                    onChange={(e) => onChange(e)}
                                  />
                                  <label>Email</label>
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='floating-label form-group'>
                                  <input
                                    className='floating-input form-control'
                                    type='password'
                                    placeholder=' '
                                    name='password'
                                    value={password}
                                    onChange={(e) => onChange(e)}
                                  />
                                  <label>Mot de passe</label>
                                </div>
                              </div>
                            </div>
                            <button type='submit' className='btn btn-primary'>
                              Connexion
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className='col-lg-5 content-right'>
                        <img
                          src='../assets/images/login/01.png'
                          className='img-fluid image-right'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isUserLoaded : PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isUserLoaded: state.user.isLoaded
});

export default connect(mapStateToProps, { login })(Login);
