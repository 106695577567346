import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Routes from './components/routing/Routes';
import Login from './components/auth/login';
import NotFound from './components/layout/NotFound';
import Alert from './components/layout/alert';

import { loadUser } from './actions-services/auth';
import setAuthToken from './assistant/setAuthToken';

//Redux-- notre fournisseur de store
import { Provider } from 'react-redux';
import store from './store';
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []); // [] :  Cela veut dire que notre Effect ne depend d'aucune valeur  des accessoire ou de l'etat,
  // donc il n'a donc jamais besoin de se réexécuté
  return (
    <Provider store={store}>
      <Router>
        <Alert/>
        <Route exact path='/' component = {Login} />
        <Switch>
        <Route component={Routes} />
        </Switch>
      </Router>
    </Provider>
  );
};
export default App;
