import React, { useState, useRef, useEffect } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MyNavbar from '../layout/navbar';
import Moment from 'react-moment';

const Vente = ({
  vente: { ventes },
  stock: { products },
  client: { clients },
  auth,
  user,
  match,
}) => {
  let vente_list = ventes.filter((item) => item.vente_id == match.params.id);
  let vente = vente_list[0];
  const [formData, setFormData] = useState({
    tab1: true,
    product_ico: false,
  });

  const counter = useRef(0);
  const [counter_state, setCounter] = useState(counter.current);

  const { tab1, product_ico } = formData;



  const handleClick1 = (event) => {
    // toggle isActive state on click
    setFormData({ ...formData, tab1: true });
  };



  const handleClick4 = (event, product_ico_new) => {
    // toggle isActive state on click
    setFormData({ ...formData, product_ico: product_ico_new });
  };


  const listeProduits = () => {
    const renderProductBranch = (
      index_produit,
      code,
      quantite,
      futs,
      id_produit,
    ) => {
      return (
        <div>
          <div className='form-row'>
            <div className='col-sm'>
              <div className='form-group'>
                <label for='exampleInputDisabled5'>Code Produit</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  value={products.filter((item,idx)=> item.produit_id === id_produit).map((produit)=> {return produit.name.toUpperCase() + ' ' + produit.category_name.toUpperCase()})}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label for='exampleInputDisabled5'>Quantité</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  value={quantite + ' Kg'}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
            <div className='col-sm'>
              <div className='form-group'>
                <label for='exampleInputDisabled5'>Nombre/Type fûts</label>
                <input
                  type='text'
                  className='form-control'
                  id='exampleInputDisabled5'
                  disabled=''
                  value={futs}
                />

                <div className='help-block with-errors'></div>
              </div>
            </div>
          </div>
          <div className='form-row'>
            
              <div className='col-sm'>
                <div className='form-group'>
                  <label for='exampleInputDisabled5'>
                    A partir du lot
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='exampleInputDisabled5'
                    disabled=''
                    value={code}
                  />

                  <div className='help-block with-errors'></div>
                </div>
              </div>
          

           
          </div>
          <p style={{ color: 'black', fontSize: '30px' }}>
            -------------------------------------------------------
          </p>
        </div>
      );
    };

    const list_quantites = vente.quantites.split(',');
    const list_futs = vente.nbre_futs.split(',');
    const list_id_produit = vente.produit_ids.split(',');
    const list_codes = vente.code.split(',');

    const result_list = [];
    for (let i = 0; i < list_codes.length; i++) {
   
      result_list.push(
        renderProductBranch(
          i,
          list_codes[i],
          list_quantites[i],
          list_futs[i],
          list_id_produit[i],
        )
      );
    }
    return result_list;
  };

  return (
    <>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>
                      Réf. N° {vente.vente_id.slice(0, 5)}
                    </h4>
                  </div>
                </div>
                <div className='card-body'>
                  <ul
                    className='d-flex nav nav-pills mb-3 text-center profile-tab'
                    id='profile-pills-tab'
                    role='tablist'
                  >
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-toggle='pill'
                        href='#profile2'
                        role='tab'
                        aria-selected='true'
                        onClick={(e) => handleClick1(e)}
                      >
                        Détails de vente
                      </a>
                    </li>
                  </ul>

                  <div className='profile-content tab-content'>
                    <div
                      id='profile2'
                      className={
                        tab1 ? 'tab-pane fade active show' : 'tab-pane fade'
                      }
                    >
                      <div className='row'>
                        <div className='col-lg-8'>
                          <div className='row'>
                            <form>
                              <div className='form-group'>
                                <label for='exampleInputDisabled5'>
                                  Client
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='exampleInputDisabled5'
                                  disabled=''
                                  value={clients
                                    .filter(
                                      (client, idx) =>
                                        client.client_id ===
                                        vente.client_id
                                    )
                                    .map((client) => {
                                      return client.code;
                                    })}
                                />
                              </div>
                              <fieldset>
                                <p>
                                  Produits achetés{' '}
                                  <span aria-label='required'>
                                    {product_ico === false ? (
                                      <i
                                        className='las la-angle-double-down'
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>
                                          handleClick4(e, !product_ico)
                                        }
                                      ></i>
                                    ) : (
                                      <i
                                        class='las la-angle-double-up'
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) =>
                                          handleClick4(e, !product_ico)
                                        }
                                      ></i>
                                    )}
                                  </span>
                                  .
                                </p>

                                {product_ico === true && listeProduits()}
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
               
                  <p className='mb-1'>
                    Date de sortie :{' '}
                    <Moment format='YYYY/MM/DD'>{vente.date_sortie}</Moment>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Vente.propTypes = {
  vente: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  stock: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
  vente: state.vente,
  auth: state.auth,
  user: state.user,
  stock: state.stock,
  client: state.client,
});

export default connect(mapStateToProps)(Vente);