import React, { Component, Fragment } from 'react';
import {PDFViewer} from '@react-pdf/renderer'
import Invoice from './Invoice'






const InvoicePdf = ({ invoice, type }) => (
    <Fragment>
    <PDFViewer width="100%" height="600" className="app" >
        <Invoice invoice={invoice} type={type}/>
    </PDFViewer>
</Fragment>
  );
  
export default InvoicePdf;