import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


  const BillTo = ({invoice, type}) => (
    <View style={styles.headerContainer}>
        {type==='achat'?(
        <Text style={styles.billTo}>Fournisseur:</Text>
        ):(
        <Text style={styles.billTo}>Client:</Text>
        )}
        <Text>{invoice.company.toString()}</Text>
        <Text>{invoice.address.toString()}</Text>
        <Text>{invoice.phone.toString()}</Text>
        <Text>{invoice.email.toString()}</Text>
    </View>
  );
  
  export default BillTo