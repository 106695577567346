import React from 'react';
import { Link } from 'react-router-dom';

const DashboardActions = () => {
  return (
    <div className='d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4'>
      <div>
        <h4 className='mb-3'>Liste des ventes</h4>
        <p className='mb-0'>
          Un tableau de bord des ventes permet au responsable de
          suivre, d'évaluer et de contrôler efficacement, et optimiser tous les
          processus d'acquisition au sein d'une entreprise.
        </p>
      </div>
      <Link to='/ventes/ajout' className='btn btn-primary add-list'>
        <i className='las la-plus mr-3'></i>Ajouter Vente
      </Link>
    </div>
  );
};

export default DashboardActions;
