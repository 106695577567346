import axios from 'axios';
import { setAlert } from './alert';
import {
  ADD_CLIENT,
  ADD_ERROR,
  CLIENTS_LOADED,
  GET_ERROR,
} from './types';

//LOAD Stock
export const loadClients = () => async (dispatch) => {
  try {
    const res = await axios.get('https://ciocap-server.herokuapp.com/api/clients');
    dispatch({
      type: CLIENTS_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
    });
  }
};
export const addClient = (code,pays) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ code, pays });

  try {
    const res = await axios.post('https://ciocap-server.herokuapp.com/api/clients', body, config);
    dispatch({
      type: ADD_CLIENT,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.data;

    dispatch({
      type: ADD_ERROR,
      payload: errors,
    });
  }
};
