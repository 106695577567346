import { configureStore } from '@reduxjs/toolkit'
import auth from './reducers/auth';
import user from './reducers/UserSummary';
import profile from './reducers/ProfileSummary';
import profiles from './reducers/profiles';
import post from './reducers/post';
import stock from './reducers/stock';
import fournisseur from './reducers/fournisseurs';
import client from './reducers/clients';
import demande from './reducers/demands';
import vente from './reducers/vente';
import alert from './reducers/alert';


const store = configureStore({reducer: { auth,
  alert,
  user,
  profile,
  profiles,
  post,
  stock,
  fournisseur,
  client,
  demande,
  vente,}});

export default store;
