import React from 'react';
import { Link } from 'react-router-dom';

const productActions = () => {
  return (
    <div className='d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4'>
      <div>
        <h4 className='mb-3'>Liste Catégories</h4>
        <p className='mb-0'>
          Utilisez la liste de catégories pour décrire votre activité principale
          globale à partir de la liste fournie. Cliquez sur le nom de la
          catégorie dans laquelle vous souhaitez ajouter un élément de liste.
        </p>
      </div>
      <Link to='/categories/ajout' className='btn btn-primary add-list'>
        <i className='las la-plus mr-3'></i>Ajouter Catégorie
      </Link>
    </div>
  );
};

export default productActions;
