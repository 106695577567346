import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

const Details = ({ clients }) => {
  const [formData, setFormData] = useState({
    entries: '10',
    entries_debut: '0',
    current_entries: '10',
    count: 1,
  });
  let { entries_debut, current_entries, entries, count } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      entries_debut: '0',
      count: 1,
      [e.target.name]: e.target.value,
      current_entries: e.target.value,
    });
  };

  const nextClick = (e) => {
    e.preventDefault();
    let prev = parseInt(entries_debut) + parseInt(current_entries);
    let next = parseInt(entries) + parseInt(current_entries);
    if (clients.length < next) {
      if(prev>clients.length){
      
      }else{
        if (
          parseInt(entries) - parseInt(entries_debut) < parseInt(entries) ||
          clients.length < parseInt(current_entries)
        ) {
          setFormData({
            entries_debut: prev.toString(),
            entries: clients.length,
            count: count + 1,
            current_entries: current_entries,
          });
        }
      }
      
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count + 1,
        current_entries: current_entries,
      });
    }
  };
  const previousClick = (e) => {
    e.preventDefault();
    let step = parseInt(entries) - parseInt(entries_debut); //11 - 10 = 1; 28 - 25 = 3//
    let next = parseInt(entries) - step; // 11 - 1 = 10; 28 - 3 = 25
    let prev = next - parseInt(current_entries); // 10 - 10 = 9; 25 - 3 = 22;  50 - 3 = 47;
    if (prev < 0) {
    } else {
      setFormData({
        entries_debut: prev.toString(),
        entries: next.toString(),
        count: count - 1,
        current_entries: current_entries,
      });
    }
  };

  const clients_table = clients
    .filter(
      (item, idx) => parseInt(entries_debut) <= idx && idx < parseInt(entries)
    )
    .map((client) => (
      <tr key={client.client_id}>
        <td>
          <div className='d-flex align-items-center'>
            <div>
              {client.code}
            </div>
          </div>
        </td>
        <td>{client.pays}</td>

        <td>
          <div className='d-flex align-items-center list-action'>
            <a
              className='badge badge-info mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='View'
              href='#'
            >
              <i className='ri-eye-line mr-0'></i>
            </a>
            <a
              className='badge bg-success mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='Edit'
              href='#'
            >
              <i className='ri-pencil-line mr-0'></i>
            </a>
            <a
              className='badge bg-warning mr-2'
              data-toggle='tooltip'
              data-placement='top'
              title=''
              data-original-title='Delete'
              href='#'
            >
              <i className='ri-delete-bin-line mr-0'></i>
            </a>
          </div>
        </td>
      </tr>
    ));

  return (
    <div className='col-lg-12'>
      <div className='table-responsive rounded mb-3'>
        <div className='dataTables_wrapper no-footer'>
          <table
            className='data-table table mb-0 tbl-server-info dataTable no-footer'
            role='grid'
          >
            <thead className='bg-white text-uppercase'>
              <tr className='ligth ligth-data'>
                <th>Code client</th>
                <th>Pays</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className='ligth-body'>{clients_table}</tbody>
          </table>
          <div className='dataTables_info' role='status'>
            Affichage de {entries_debut} à {entries} entrées
          </div>
          <div className='dataTables_paginate paging_simple_numbers'>
            <a
              className='paginate_button previous disabled'
              onClick={(e) => previousClick(e)}
            >
              Précédent
            </a>
            <span>
              <a className='paginate_button current'>{count}</a>
            </span>
            <a
              className='paginate_button next disabled'
              onClick={(e) => nextClick(e)}
            >
              Suivant
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
clients: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  clients: state.client.clients,
});

export default connect(mapStateToProps)(Details);
