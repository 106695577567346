import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import Details from './details';
import { loadVentes } from '../../actions-services/ventes';
import store from '../../store';
import MyNavbar from '../layout/navbar';
const Ventes = ({ ventes }) => {
  useEffect(() => {
    store.dispatch(loadVentes());
  }, [loadVentes]);

  return (
    <Fragment>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <DashboardActions />
            </div>
            {ventes !== null && ventes !== undefined && ventes !== [] ? (
              <div>
                <Details />
              </div>
            ) : (
          
                <p>
                  Vous n'avez pas encore ajouté une vente, veuillez ajouter
                  une vente...
                </p>
        
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Ventes.propTypes = {
  ventes: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  ventes: state.vente.ventes,
});

export default connect(mapStateToProps)(Ventes);
