import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardActions from './DashboardActions';
import Details from './details';
import MyNavbar from '../layout/navbar';
import { loadFournisseurs } from '../../actions-services/fournisseurs';
import store from '../../store';

const Fournisseurs = ({ fournisseurs }) => {
  useEffect(() => {
    store.dispatch(loadFournisseurs());
  }, [loadFournisseurs]);
  return (
    <Fragment>
      <MyNavbar />
      <div className='content-page'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <DashboardActions />
            </div>
            {fournisseurs !== null &&
            fournisseurs !== undefined &&
            fournisseurs !== [] ? (
              <div>
                <Details />
              </div>
            ) : (
              <Fragment>
                <p>
                  Vous n'avez pas encore ajouté un fournisseur, Cliquer sur
                  ajouter pour enregistrer un nouveau fournisseur...
                </p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Fournisseurs.propTypes = {
  fournisseurs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  fournisseurs: state.fournisseur.fournisseurs,
});

export default connect(mapStateToProps)(Fournisseurs);
