import {
  VENTES_LOADED,
  ADD_VENTE,
  ADD_ERROR,
  GET_ERROR,
} from '../actions-services/types';

const initialState = {
  loading: true,
  ventes: [],
  new_vente: null,
  isAdded : false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case VENTES_LOADED:
      localStorage.setItem('ventes', payload);
      return {
        ...state,
        ...payload,
        loading: false,
        isAdded: false,
      };
    case ADD_VENTE:
      return {
        ...state,
        new_vente: payload.msg,
        loading: false,
        isAdded: true,
      };
    case GET_ERROR:
    case ADD_ERROR:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
